import {
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import { Box } from "@mui/system";
import { toast, ToastContainer } from "react-toastify";

const DistrictDetails = () => {
  // pagination and loader
  const [loaders, setLoaders] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // toast message
  const districtAlreadyAdded = () => {
    toast.error("District already added!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const districtAdded = () => {
    toast.success("District added successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const districtupdatesuccess = () => {
    toast.success("District updated successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const districtDeletedSuccess = () => {
    toast.success("District deleted successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const textStyle = { marginBottom: "25px" };

  const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [getDistrictData, setGetDistrictData] = useState([]);

  // District Data
  const [adddistricts, setAddDistricts] = useState({
    district_name_en: "",
    district_name_mr: "",
  });
  let name, value;
  const handleOnChangeDistrict = (e) => {
    name = e.target.name;
    value = e.target.value;
    setAddDistricts({ ...adddistricts, [name]: value });
  };
  const [viewDistricts, setViewDistricts] = useState([]);

  const [viewSingleData, SetViewSingleData] = useState({
    district_name_en: "",
    district_name_mr: "",
  });

  // viwe district model open
  const [openViewModel, setOpenViewModel] = React.useState(false);
  const handleOpenViewModel = (vals) => {
    setOpenViewModel(true);
    SetViewSingleData({
      district_name_en: vals?.district_name_en.trim(),
      district_name_mr: vals?.district_name_mr.trim(),
    });
  };
  const handleCloseViewModel = () => setOpenViewModel(false);

  // add district model open
  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [updateDistrict, setUpdateDistrict] = useState({
    _id: "",
    district_name_en: "",
    district_name_mr: "",
  });

  const handleOnchangeUpdate = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUpdateDistrict({ ...updateDistrict, [name]: value });
  };

  //edit district model open
  const [openEditModel, setOpenEditModel] = React.useState(false);
  const handleOpenEditModel = (vals) => {
    setOpenEditModel(true);
    setUpdateDistrict({
      _id: vals?._id,
      district_name_en: vals?.district_name_en,
      district_name_mr: vals?.district_name_mr,
    });
  };
  const handleCloseEditModel = () => setOpenEditModel(false);

  // delete district model open
  const [deleteDistrict, setDeleteDistrict] = useState({
    _id: "",
  });

  const [openDeleteModel, setOpenDeleteModel] = React.useState(false);
  const handleOpenDeleteModel = (vals) => {
    setOpenDeleteModel(true);
    setDeleteDistrict({
      _id: vals?._id,
    });
  };
  const handleCloseDeleteModel = () => setOpenDeleteModel(false);

  // view district
  useEffect(() => {
    viewdistrict();
  }, []);

  const viewdistrict = () => {
    axios
      .get(process.env.REACT_APP_ALL_API + "/district/getalldistricts")
      .then((vals) => {
        setViewDistricts(vals?.data?.data.reverse());
      })
      .catch((error) => console.log(error));
  };

  // Delete district record
  const deleteDistrictRecord = async () => {
    const { _id } = deleteDistrict;
    const payloadData = {
      _id: _id,
    };
    await axios
      .post(
        process.env.REACT_APP_ALL_API + "/district/deletedistrict",
        payloadData
      )
      .then((res) => {
        districtDeletedSuccess();
        viewdistrict();
        setOpenDeleteModel(false);
      })
      .catch((error) => console.log(error));
  };

  //add district
  const adddistrict = async (e) => {
    e.preventDefault();
    const { _id, district_name_en, district_name_mr } = adddistricts;
    const data = {
      _id: _id,
      district_name_en:
        district_name_en.charAt(0).toUpperCase() +
        district_name_en.slice(1).trim(),
      district_name_mr: district_name_mr.trim(),
    };
    await axios
      .post(process.env.REACT_APP_ALL_API + "/district/adddistrict", data)
      .then((res) => {
        setGetDistrictData(res);
        viewdistrict();
        setAddDistricts({
          district_name_en: "",
          district_name_mr: "",
        });
        if (res?.data?.message === "District already added") {
          districtAlreadyAdded();
          setOpen(false);
          // setGetDistrictData();
        } else if (res?.data?.message === "District Added Successfully") {
          districtAdded();
          setOpen(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const updateDistrictData = async (e) => {
    e.preventDefault(e);
    const { _id, district_name_en, district_name_mr } = updateDistrict;
    const payloadData = {
      _id: _id,
      district_name_en:
        district_name_en.charAt(0).toUpperCase() +
        district_name_en.slice(1).trim(),
      district_name_mr: district_name_mr.trim(),
    };
    await axios
      .post(
        process.env.REACT_APP_ALL_API + "/district/updatedistrict",
        payloadData
      )
      .then((res) => {
        districtupdatesuccess();
        viewdistrict();
        setOpenEditModel(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <ToastContainer autoClose={2000} />
      <Modal
        style={{}}
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Add District
            </Typography>
            <form onSubmit={(e) => adddistrict(e)}>
              <TextField
                label="District"
                variant="outlined"
                placeholder="Enter District name"
                fullWidth
                required
                size="small"
                name="district_name_en"
                style={textStyle}
                value={adddistricts?.district_name_en}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:{}\[\]*.|//\/^.?<>,;`'""/\\]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    handleOnChangeDistrict(e);
                  } else if (e.target.value.length === 0) {
                    handleOnChangeDistrict(e);
                  }
                }}
              />
              <TextField
                label="जिल्हा"
                variant="outlined"
                placeholder="जिल्ह्याचे नाव प्रविष्ट करा"
                fullWidth
                required
                size="small"
                name="district_name_mr"
                style={textStyle}
                value={adddistricts?.district_name_mr}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                    e.target.value.trim()
                  ) {
                    handleOnChangeDistrict(e);
                  } else if (e.target.value.length === 0) {
                    handleOnChangeDistrict(e);
                  }
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#a8c74d",
                  padding: "10px 0px",
                  "&:hover": {
                    color: "#a8c74d",
                    backgroundColor: "white",
                    transition: "all 0.2s",
                  },
                }}
              >
                Add
              </Button>
            </form>
          </Paper>
        </Box>
      </Modal>
      <Modal
        style={{}}
        keepMounted
        open={openEditModel}
        onClose={handleCloseEditModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Update District
            </Typography>
            <form onSubmit={(e) => updateDistrictData(e)}>
              <TextField
                label="District"
                variant="outlined"
                fullWidth
                required
                size="small"
                name="district_name_en"
                style={textStyle}
                value={updateDistrict?.district_name_en}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    handleOnchangeUpdate(e);
                  } else if (e.target.value.length === 0) {
                    handleOnchangeUpdate(e);
                  }
                }}
              />
              <TextField
                label="जिल्हा"
                variant="outlined"
                fullWidth
                required
                size="small"
                name="district_name_mr"
                style={textStyle}
                value={updateDistrict?.district_name_mr}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                    e.target.value.trim()
                  ) {
                    handleOnchangeUpdate(e);
                  } else if (e.target.value.length === 0) {
                    handleOnchangeUpdate(e);
                  }
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#a8c74d",
                  padding: "10px 0px",
                  "&:hover": {
                    color: "#a8c74d",
                    backgroundColor: "white",
                    transition: "all 0.2s",
                  },
                }}
              >
                Update
              </Button>
            </form>
          </Paper>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDeleteModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper
            style={{
              padding: "25px",
            }}
          >
            <Typography
              style={{ textAlign: "center", margin: "10px" }}
              variant="h6"
            >
              Are you sure You want to delete?
            </Typography>
            <Typography
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                size="small"
                style={{ backgroundColor: "green", color: "white" }}
                onClick={handleCloseDeleteModel}
              >
                No
              </Button>
              <Button
                variant="outlined"
                style={{ backgroundColor: "red", color: "white" }}
                onClick={deleteDistrictRecord}
              >
                Yes
              </Button>
            </Typography>
          </Paper>
        </Box>
      </Modal>
      <Modal
        style={{}}
        keepMounted
        open={openViewModel}
        onClose={handleCloseViewModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              View District Data
            </Typography>
            <TextField
              label="District"
              variant="outlined"
              fullWidth
              required
              size="small"
              style={textStyle}
              value={viewSingleData?.district_name_en}
            />
            <TextField
              label="जिल्हा"
              variant="outlined"
              fullWidth
              required
              size="small"
              style={textStyle}
              value={viewSingleData?.district_name_mr}
            />
          </Paper>
        </Box>
      </Modal>

      <Paper
        elevation={1}
        sx={{
          width: "100%",
          marginBottom: "10px",
          height: "60px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
            }}
          >
            <Typography variant="h6" sx={{ margin: "12px", fontWeight: "500" }}>
              District Data
            </Typography>
          </div>
          <div style={{ padding: "10px" }}>
            <Button
              sx={{
                color: "white",
                backgroundColor: "#a8c74d",
                "&:hover": {
                  backgroundColor: "#c9ec60",
                  color: "white",
                  transition: "all 0.2s",
                },
              }}
              onClick={handleOpen}
            >
              Add District
              <AddBoxOutlined />
            </Button>
          </div>
        </div>
      </Paper>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              hover
              className="tablehead"
              sx={{
                backgroundColor: "#a8c74d",
              }}
            >
              <TableRow>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Sr.No
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  District Name English
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  District Name Marathi
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Edit
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  View
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loaders ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    class="spinner-border"
                    role="status"
                    style={{ display: "flex", justifyContent: "center" }}
                  ></div>

                  <span class="sr-only"></span>
                </div>
              ) : (
                viewDistricts !== undefined &&
                viewDistricts !== null &&
                viewDistricts.length > 0 &&
                viewDistricts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((vals, index) => {
                    return (
                      <TableRow hover>
                        {page !== 0 ? (
                          <TableCell>
                            {index + 1 + rowsPerPage * page}
                          </TableCell>
                        ) : (
                          <TableCell>{index + 1}</TableCell>
                        )}
                        <TableCell>{vals?.district_name_en}</TableCell>
                        <TableCell>{vals?.district_name_mr}</TableCell>
                        <TableCell>
                          <EditOutlinedIcon
                            onClick={() => handleOpenEditModel(vals)}
                            sx={{
                              color: "white",
                              backgroundColor: "#a8c74d",
                              borderRadius: "50%",
                              padding: "2px",

                              "&:hover": {
                                backgroundColor: "#c9ec60",
                                color: "white",
                                transition: "all 0.2s",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <ViewListOutlinedIcon
                            onClick={() => handleOpenViewModel(vals)}
                            sx={{
                              color: "white",
                              backgroundColor: "#a8c74d",
                              borderRadius: "50%",
                              padding: "2px",

                              "&:hover": {
                                backgroundColor: "#c9ec60",
                                color: "white",
                                transition: "all 0.2s",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <DeleteOutlined
                            onClick={() => handleOpenDeleteModel(vals)}
                            sx={{
                              color: "white",
                              backgroundColor: "#a8c74d",
                              borderRadius: "50%",
                              padding: "2px",

                              "&:hover": {
                                backgroundColor: "#c9ec60",
                                color: "white",
                                transition: "all 0.2s",
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {viewDistricts !== undefined &&
        viewDistricts !== null &&
        viewDistricts.length > 0 ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 100]}
            count={
              viewDistricts === null && viewDistricts === undefined
                ? 0
                : viewDistricts
                ? viewDistricts.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          ""
        )}
      </Paper>
    </>
  );
};

export default DistrictDetails;
