import React, { useState, useEffect } from "react";
// import SkeletonTheme from "react-loading-skeleton";
import { Col, Container, Row } from "react-bootstrap";
import LoadingSckeloton from "../../loadingSckeleton/LoadingSkeleton";
// import "react-loading-skeleton/dist/skeleton.css";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from "@mui/material";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const ViewUserDetails = () => {
  const [userData, setUserData] = useState({
    district: "",
    taluka: "",
    grampanchayat: "",
  });

  // toast message
  const datanotfounderror = () => {
    toast.error("Please add Grampanchayat!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  let name, value;
  const handleOnChange = (e) => {
    // setUserData({...userData,]})
    name = e.target.name;
    value = e.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const indexOfLastPost = page * rowsPerPage;
  // const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  const [districtData, setDistrictData] = useState([]);
  const [talukaData, setTalukaData] = useState([]);
  const [grampanchayatData, setGrampanchayatData] = useState([]);

  const [talukaDataArray, setTalukaDataArray] = useState([]);
  const [grampanchayatDataArray, setGrampanchayatDataArray] = useState([]);

  const [loaders, setLoaders] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // get districts API
  const getDist = () => {
    axios
      .get(process.env.REACT_APP_ALL_API + "/district/getalldistricts")
      .then((res) => {
        setDistrictData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get Taluka API
  const getTaluka = () => {
    axios
      .get(process.env.REACT_APP_ALL_API + "/taluka/getalltalukas")
      .then((res) => {
        setTalukaData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get Grampanchayat API
  const getGrampanchayat = () => {
    axios
      .get(
        process.env.REACT_APP_ALL_API + "/grampanchayat/getallgrampanchayats"
      )
      .then((res) => {
        setGrampanchayatData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDist();
    getTaluka();
    getGrampanchayat();
  }, []);

  useEffect(() => {
    setGrampanchayatDataArray([""]);
    setUserData({ ...userData, taluka: "", grampanchayat: "" });
    if (userData?.district) {
      getTalukaData();
    }
  }, [userData?.district]);

  const getTalukaData = () => {
    const result = talukaData?.filter(
      (event) => event.district_id === userData?.district
    );
    setTalukaDataArray(result);
  };

  useEffect(() => {
    setUserData({ ...userData, grampanchayat: "" });
    if (userData?.taluka) {
      getGrampanchayatData();
    }
  }, [userData?.taluka]);

  const getGrampanchayatData = () => {
    const resultGram = grampanchayatData.filter(
      (event) => event.taluka_id === userData.taluka
    );
    setGrampanchayatDataArray(resultGram);
  };

  const [allData, setAllData] = useState([]);

  useEffect(() => {
    getUserData();
  }, [userData?.district, userData?.taluka, userData?.grampanchayat]);

  // API for search
  const getUserData = async () => {
    const payloadData = {
      district: userData?.district,
      taluka: userData?.taluka,
      grampanchayat: userData?.grampanchayat,
    };

    const payloadData0 = {
      district: userData?.district,
      taluka: userData?.taluka,
    };

    const payloadData1 = {
      district: userData?.district,
    };
    setLoaders(true);
    await axios
      .post(
        process.env.REACT_APP_ALL_API +
          "/gaonConnectWhatsapp/getgaonconnectwhatsappdetailswithfilter",
        userData?.district && userData?.taluka && userData?.grampanchayat
          ? payloadData
          : userData?.district && userData?.taluka
          ? payloadData0
          : userData?.district
          ? payloadData1
          : ""
      )
      .then((res) => {
        setAllData(res.data.data.data);
        console.log("users data...............**8",res)
        // setLoaders(false);
        // if (res.data.message === "No Data Found") {
        //   datanotfounderror();
        // }
      })
      .catch((error) => {
        console.log(error);
        setLoaders(false);
      });
  };

  //excel sheet code
  const [sheetLoader, setSheetLoader] = useState(false);

  const downloadExcel = (customHeadings) => {
    let dataSet = [];
    dataSet = customHeadings;
    const worksheet = XLSX.utils.json_to_sheet(dataSet);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "UserDeatils.xlsx");
    setSheetLoader(false);
  };

  const CustomExcel = () => {
    setSheetLoader(true);
    const customHeadings =
      allData !== undefined && allData.length > 0
        ? allData.map((data) => ({
            Name: data.name ?? "-",
            "Contact No": data.contact_no ?? "-",
            Gender: data.gender ?? "-",
            District: data.district_name_en ?? "-",
            जिल्हा: data.district_name_mr ?? "-",
            Taluka: data.taluka_name_en ?? "-",
            तालुका: data.taluka_name_mr ?? "-",
            Grampanchayat: data.grampanchayat_name_en ?? "-",
            ग्रामपंचायत: data.grampanchayat_name_mr ?? "-",
            Village: data.village_name_en ?? "-",
            गाव: data.village_name_mr ?? "-",
          }))
        : "";
    downloadExcel(customHeadings);
  };

  return (
    <>
      <ToastContainer autoClose={2000} />
      <Paper
        className="paper-wrapper"
        sx={{ width: "100%", margin: "10px auto", padding: "15px 0px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            margin: "10px",
          }}
        >
          <FormControl sx={{ m: 2, minWidth: 180 }} size="small">
            <InputLabel id="demo-select-small">District</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              name="district"
              value={userData?.district}
              label="District"
              onChange={(e) => handleOnChange(e)}
            >
              <MenuItem value="" selected disabled>
                <em>Select District</em>
              </MenuItem>
              {districtData !== undefined &&
              districtData !== null &&
              districtData?.length > 0
                ? districtData?.map((vals, index) => {
                    return (
                      <MenuItem value={vals?._id}>
                        {vals?.district_name_en}
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 2, minWidth: 180 }} size="small">
            <InputLabel id="demo-select-small">Taluka</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              name="taluka"
              value={userData?.taluka}
              label="Taluka"
              onChange={handleOnChange}
            >
              <MenuItem value="" selected disabled>
                <em>Select Taluka</em>
              </MenuItem>
              {talukaDataArray !== undefined &&
              talukaDataArray !== null &&
              talukaDataArray?.length > 0
                ? talukaDataArray?.map((vals, index) => {
                    return (
                      <MenuItem value={vals?._id}>
                        {vals?.taluka_name_en}
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 2, minWidth: 180 }} size="small">
            <InputLabel id="demo-select-small">Grampanchayat</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              name="grampanchayat"
              value={userData?.grampanchayat}
              label="Grampanchayat"
              onChange={handleOnChange}
            >
              <MenuItem value="" selected disabled>
                <em>Select Grampanchayat</em>
              </MenuItem>
              {grampanchayatDataArray !== undefined &&
              grampanchayatDataArray !== null &&
              grampanchayatDataArray?.length > 0
                ? grampanchayatDataArray?.map((vals, index) => {
                    return (
                      <MenuItem value={vals?._id}>
                        {vals?.grampanchayat_name_en}
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{
              m: 1,
              backgroundColor: "#a8c74d",
              "&:hover": { backgroundColor: "white", color: "#a8c74d" },
            }}
            onClick={() => CustomExcel()}
          >
            Excel Sheet <FileDownloadIcon />
          </Button>
        </div>

        {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Sr.No
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Name
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Contact
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Gender
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  District
                </TableCell>
                {/* <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                जिल्हा
              </TableCell> */}
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Taluka
                </TableCell>
                {/* <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                तालुका
              </TableCell> */}
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Grampanchayat
                </TableCell>
                {/* <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                ग्रामपंचायत
              </TableCell> */}
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Village
                </TableCell>
                {/* <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                गाव
              </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* const indexOfLastPost = currentpage * showperpage;
  const indexOfFirstPost = indexOfLastPost - showperpage; */}
              {!loaders ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    class="spinner-border"
                    role="status"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <span class="sr-only"></span>
                  </div>
                  
                </div>
              ) : allData !== undefined &&
                allData !== null &&
                allData.length > 0 ? (
                allData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((vals, index) => {
                    return (
                      <TableRow hover>
                        {page !== 0 ? (
                          <TableCell>
                            {index + 1 + rowsPerPage * page}
                          </TableCell>
                        ) : (
                          <TableCell>{index + 1 ? index + 1 : 0}</TableCell>
                        )}
                        <TableCell>{vals?.name}</TableCell>
                        <TableCell>{vals?.contact_no}</TableCell>
                        <TableCell>{vals?.gender}</TableCell>
                        <TableCell>{vals?.district_name_en}</TableCell>
                        {/* <TableCell>{vals?.district_name_mr}</TableCell> */}
                        <TableCell>{vals?.taluka_name_en}</TableCell>
                        {/* <TableCell>{vals?.taluka_name_mr}</TableCell> */}
                        <TableCell>{vals?.grampanchayat_name_en}</TableCell>
                        {/* <TableCell>{vals?.grampanchayat_name_mr}</TableCell> */}
                        <TableCell>{vals?.village_name_en}</TableCell>
                        {/* <TableCell>{vals?.village_name_mr}</TableCell> */}
                      </TableRow>
                    );
                  })
              ) : (
                "User data not found"
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {allData !== null && allData !== undefined && allData.length > 0 ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 100]}
            count={
              allData === null && allData === undefined
                ? 0
                : allData
                ? allData.length
                : 0
            }
            rowsPerPage={rowsPerPage ? rowsPerPage : ""}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          ""
        )}
      </Paper>
    </>
  );
};

export default ViewUserDetails;
