import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import { toast, ToastContainer } from "react-toastify";
import EditOutlined from "@mui/icons-material/EditOutlined";

const TalukaDetails = () => {
  // pagination and loader
  const [loaders, setLoaders] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // toast message
  const talukaAlreadyAdded = () => {
    toast.error("Taluka already added!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const talukaAdded = () => {
    toast.success("Taluka added successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const districtNotAdded = () => {
    toast.error("District not found. Add district first!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const updatedtalukasucess = () => {
    toast.success("Taluka updated successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const deleteTalukaSuccess = () => {
    toast.success("Taluka deleted successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const talukanotfound = () => {
    toast.success("Taluka data not found, Please add taluka!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const textStyle = { marginBottom: "25px" };

  const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  // open add taluka modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [updateTaluka, setUpdateTaluka] = useState({
    _id: "",
    taluka_name_en: "",
    taluka_name_mr: "",
  });

  const handleOnchangeUpdateTaluka = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUpdateTaluka({ ...updateTaluka, [name]: value });
  };
  // update taluka model
  const [openEditTalukaModel, setOpenEditTalukaModel] = React.useState(false);
  const handleOpenEditTalukaModel = (vals) => {
    setOpenEditTalukaModel(true);
    setUpdateTaluka({
      _id: vals?._id,
      taluka_name_en: vals?.taluka_name_en,
      taluka_name_mr: vals?.taluka_name_mr,
    });
  };
  const handleCloseEditTalukaModel = () => setOpenEditTalukaModel(false);

  // View taluka model
  const [viewTaluka, setViewTaluka] = useState({
    district_name_en: "",
    taluka_name_en: "",
    taluka_name_mr: "",
  });

  const [openViewTalukaModel, setOpenViewTalukaModel] = React.useState(false);
  const handleOpenViewTalukaModel = (vals) => {
    setOpenViewTalukaModel(true);
    setViewTaluka({
      district_name_en: vals?.district_name_en,
      taluka_name_en: vals?.taluka_name_en,
      taluka_name_mr: vals?.taluka_name_mr,
    });
  };
  const handleCloseViewTalukaModel = () => setOpenViewTalukaModel(false);

  // delete taluka model
  const [deleteTalukaById, setDeleteTalukaById] = useState({
    _id: "",
  });
  const [openDeleteTalukaModel, setOpenDeleteTalukaModel] =
    React.useState(false);
  const handleDeleteTalukaModel = (vals) => {
    setOpenDeleteTalukaModel(true);
    setDeleteTalukaById({
      _id: vals?._id,
    });
  };
  const handleCloseDeleteTalukaModel = () => setOpenDeleteTalukaModel(false);

  const [districtData, setDistrictData] = useState([]);
  const [talukaDataByDistrictId, setTalukaDataByDistrictId] = useState([]);
  const [selectDistrict, setSelectDistrict] = useState({
    district: "",
  });

  let name, value;
  const handleOnChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setSelectDistrict({ ...selectDistrict, [name]: value });
  };

  const [selectModelDistrict, setSelectModelDistrict] = useState({
    modeldistrict: "",
  });
  // model data
  // const handleModelOnChange = (e) => {
  //   name = e.target.name;
  //   value = e.target.value;
  //   setSelectModelDistrict({ ...selectModelDistrict, [name]: value });
  // };

  const [talukaData, setTalukaData] = useState({
    district_name_en: "",
    taluka_name_en: "",
    taluka_name_mr: "",
  });

  const handleTalukaOnChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setTalukaData({ ...talukaData, [name]: value });
  };

  //add district
  const addTaluka = async (e) => {
    e.preventDefault(e);
    const { district_name_en, taluka_name_en, taluka_name_mr } = talukaData;
    const data = {
      district_name_en: district_name_en,
      taluka_name_en:
        taluka_name_en.charAt(0).toUpperCase() + taluka_name_en.slice(1).trim(),
      taluka_name_mr: taluka_name_mr.trim(),
    };
    await axios
      .post(process.env.REACT_APP_ALL_API + "/taluka/addtaluka", data)
      .then((res) => {
        setTalukaData({
          ...talukaData,
          district_name_en: "",
          taluka_name_en: "",
          taluka_name_mr: "",
        });
        if (res.data.message === "Taluka already added") {
          talukaAlreadyAdded();
          setOpen(false);
        } else if (res.data.message === "Taluka Added Successfully") {
          talukaAdded();
          getDistrictDataByTalukaId();

          setOpen(false);
        } else if (
          res.data.message === "District not found. Add district first"
        ) {
          districtNotAdded();
        }
      })
      .catch((error) => console.log(error));
  };

  //view district
  useEffect(() => {
    getDistrictData();
  }, []);

  const getDistrictData = () => {
    axios
      .get(process.env.REACT_APP_ALL_API + "/district/getalldistricts")
      .then((res) => {
        setDistrictData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDistrictDataByTalukaId();
  }, [selectDistrict.district]);

  // get talukaData by district id
  const getDistrictDataByTalukaId = async () => {
    const payload = { district_id: selectDistrict?.district };
    await axios
      .post(
        process.env.REACT_APP_ALL_API + "/taluka/gettalukabydistrictid",
        selectDistrict.district ? payload : ""
      )
      .then((response) => {
        setTalukaDataByDistrictId(response?.data?.data?.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTalukaData = async (e) => {
    e.preventDefault(e);
    const { _id, taluka_name_en, taluka_name_mr } = updateTaluka;
    const data = {
      _id: _id,
      taluka_name_en:
        taluka_name_en.charAt(0).toUpperCase() + taluka_name_en.slice(1).trim(),
      taluka_name_mr: taluka_name_mr.trim(),
    };
    await axios
      .post(process.env.REACT_APP_ALL_API + "/taluka/updatetaluka", data)
      .then((res) => {
        getDistrictDataByTalukaId();
        if (res.data.message === "Taluka updated successfully") {
          updatedtalukasucess();
          setOpenEditTalukaModel(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTalukaData = async () => {
    const { _id } = deleteTalukaById;
    const datas = {
      _id: _id,
    };
    await axios
      .post(process.env.REACT_APP_ALL_API + "/taluka/deletetaluka", datas)
      .then((res) => {
        getDistrictDataByTalukaId();
        if (res.data.message === "Taluka deleted successfully") {
          deleteTalukaSuccess();
          setOpenDeleteTalukaModel(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("talukaDataByDistrictId", talukaDataByDistrictId);

  return (
    <>
      <ToastContainer autoClose={2000} />
      <Modal
        style={{}}
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Add Taluka
            </Typography>
            <form onSubmit={(e) => addTaluka(e)}>
              <FormControl
                sx={{ minWidth: 340, marginBottom: "25px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">District</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="district_name_en"
                  required
                  value={talukaData?.district_name_en}
                  label="District"
                  onChange={(e) => handleTalukaOnChange(e)}
                >
                  <MenuItem selected disabled>
                    <em>Select District</em>
                  </MenuItem>
                  {districtData !== undefined &&
                  districtData !== null &&
                  districtData.length > 0
                    ? districtData.map((vals, index) => {
                        return (
                          <MenuItem value={vals?.district_name_en}>
                            {vals?.district_name_en}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>
              <TextField
                label="Taluka"
                variant="outlined"
                placeholder="Enter Taluka Name"
                fullWidth
                required
                size="small"
                name="taluka_name_en"
                style={textStyle}
                value={talukaData?.taluka_name_en}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    handleTalukaOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleTalukaOnChange(e);
                  }
                }}
              />
              <TextField
                label="तालुका"
                variant="outlined"
                placeholder="तालुका प्रविष्ट करा"
                fullWidth
                required
                size="small"
                name="taluka_name_mr"
                style={textStyle}
                value={talukaData?.taluka_name_mr}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                    e.target.value.trim()
                  ) {
                    handleTalukaOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleTalukaOnChange(e);
                  }
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#a8c74d",
                  padding: "10px 0px",
                  "&:hover": {
                    color: "#a8c74d",
                    backgroundColor: "white",
                    transition: "all 0.2s",
                  },
                }}
              >
                Add
              </Button>
            </form>
          </Paper>
        </Box>
      </Modal>

      <Modal
        style={{}}
        keepMounted
        open={openEditTalukaModel}
        onClose={handleCloseEditTalukaModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Update Taluka
            </Typography>
            <form onSubmit={(e) => updateTalukaData(e)}>
              <TextField
                label="Taluka"
                variant="outlined"
                fullWidth
                required
                size="small"
                name="taluka_name_en"
                style={textStyle}
                value={updateTaluka?.taluka_name_en}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    handleOnchangeUpdateTaluka(e);
                  } else if (e.target.value.length === 0) {
                    handleOnchangeUpdateTaluka(e);
                  }
                }}
              />
              <TextField
                label="ग्रामपंचायत"
                variant="outlined"
                fullWidth
                required
                size="small"
                name="taluka_name_mr"
                style={textStyle}
                value={updateTaluka?.taluka_name_mr}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                    e.target.value.trim()
                  ) {
                    handleOnchangeUpdateTaluka(e);
                  } else if (e.target.value.length === 0) {
                    handleOnchangeUpdateTaluka(e);
                  }
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#a8c74d",
                  padding: "10px 0px",
                  "&:hover": {
                    color: "#a8c74d",
                    backgroundColor: "white",
                    transition: "all 0.2s",
                  },
                }}
              >
                Update
              </Button>
            </form>
          </Paper>
        </Box>
      </Modal>

      <Modal
        style={{}}
        keepMounted
        open={openViewTalukaModel}
        onClose={handleCloseViewTalukaModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              View Taluka Data
            </Typography>
            <TextField
              label="District"
              variant="outlined"
              fullWidth
              required
              size="small"
              name="district_name_en"
              style={textStyle}
              value={viewTaluka?.district_name_en}
            />
            <TextField
              label="Taluka"
              variant="outlined"
              fullWidth
              required
              size="small"
              name="taluka_name_en"
              style={textStyle}
              value={viewTaluka?.taluka_name_en}
            />
            <TextField
              label="ग्रामपंचायत"
              variant="outlined"
              fullWidth
              required
              size="small"
              name="taluka_name_mr"
              style={textStyle}
              value={viewTaluka?.taluka_name_mr}
            />
          </Paper>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteTalukaModel}
        onClose={handleCloseDeleteTalukaModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper
            style={{
              padding: "25px",
            }}
          >
            <Typography
              style={{ textAlign: "center", margin: "10px" }}
              variant="h6"
            >
              Are you sure You want to delete?
            </Typography>
            <Typography
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                size="small"
                style={{ backgroundColor: "green", color: "white" }}
                onClick={handleCloseDeleteTalukaModel}
              >
                No
              </Button>
              <Button
                variant="outlined"
                style={{ backgroundColor: "red", color: "white" }}
                onClick={deleteTalukaData}
              >
                Yes
              </Button>
            </Typography>
          </Paper>
        </Box>
      </Modal>
      <Paper
        elevation={1}
        sx={{
          width: "100%",
          marginBottom: "10px",
          height: "60px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
              <InputLabel id="demo-select-small">District</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                name="district"
                value={selectDistrict.district}
                label="District"
                onChange={(e) => handleOnChange(e)}
              >
                <MenuItem selected disabled>
                  <em>Select District</em>
                </MenuItem>
                {districtData !== undefined &&
                districtData !== null &&
                districtData.length > 0
                  ? districtData.map((vals, index) => {
                      return (
                        <MenuItem value={vals?._id}>
                          {vals?.district_name_en}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>
          </div>
          <div style={{ padding: "10px" }}>
            <Button
              sx={{
                color: "white",
                backgroundColor: "#a8c74d",
                "&:hover": {
                  backgroundColor: "#c9ec60",
                  color: "white",
                  transition: "all 0.2s",
                },
              }}
              onClick={handleOpen}
            >
              Add Taluka
              <AddBoxOutlined />
            </Button>
          </div>
        </div>
      </Paper>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              hover
              className="tablehead"
              sx={{
                backgroundColor: "#a8c74d",
              }}
            >
              <TableRow>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Sr.No
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  District
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Taluka Name English
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Taluka Name Marathi
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Edit
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  View
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loaders ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    class="spinner-border"
                    role="status"
                    style={{ display: "flex", justifyContent: "center" }}
                  ></div>

                  <span class="sr-only"></span>
                </div>
              ) : talukaDataByDistrictId !== undefined &&
                talukaDataByDistrictId !== null &&
                talukaDataByDistrictId.length > 0 ? (
                talukaDataByDistrictId
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((vals, index) => {
                    return (
                      <TableRow hover>
                        {page !== 0 ? (
                          <TableCell>
                            {index + 1 + rowsPerPage * page}
                          </TableCell>
                        ) : (
                          <TableCell>{index + 1}</TableCell>
                        )}
                        <TableCell>{vals?.district_name_en}</TableCell>
                        <TableCell>{vals?.taluka_name_en}</TableCell>
                        <TableCell>{vals?.taluka_name_mr}</TableCell>

                        <TableCell>
                          <EditOutlined
                            onClick={() => handleOpenEditTalukaModel(vals)}
                            sx={{
                              color: "white",
                              backgroundColor: "#a8c74d",
                              borderRadius: "50%",
                              padding: "2px",

                              "&:hover": {
                                backgroundColor: "#c9ec60",
                                color: "white",
                                transition: "all 0.2s",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <ViewListOutlinedIcon
                            onClick={() => handleOpenViewTalukaModel(vals)}
                            sx={{
                              color: "white",
                              backgroundColor: "#a8c74d",
                              borderRadius: "50%",
                              padding: "2px",

                              "&:hover": {
                                backgroundColor: "#c9ec60",
                                color: "white",
                                transition: "all 0.2s",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <DeleteOutlined
                            onClick={() => handleDeleteTalukaModel(vals)}
                            sx={{
                              color: "white",
                              backgroundColor: "#a8c74d",
                              borderRadius: "50%",
                              padding: "2px",

                              "&:hover": {
                                backgroundColor: "#c9ec60",
                                color: "white",
                                transition: "all 0.2s",
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                "Data not found"
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {talukaDataByDistrictId !== undefined &&
        talukaDataByDistrictId !== null &&
        talukaDataByDistrictId.length > 0 ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 100]}
            count={
              talukaDataByDistrictId === null &&
              talukaDataByDistrictId === undefined
                ? 0
                : talukaDataByDistrictId
                ? talukaDataByDistrictId.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          ""
        )}
      </Paper>
    </>
  );
};

export default TalukaDetails;
