import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { BASE_URL } from "../Utils/urls";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Footer from "./Footer";
import NavBar from "./NavBar";
import axios from "axios";

const Login = () => {
  const notify = () => {
    toast.error("Please enter valid credentials", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const mobileError = () => {
    toast.error("Please Enter Valid Mobile Number", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const APiError = () => {
    toast.warning("Something Went Wrong !!.Please Try Again", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const Invalid = () => {
    toast.error("Please enter valid mobile number and password !!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const navigate = useNavigate();

  const [Mobile, setMobile] = useState("");
  console.log(Mobile);
  const [Pass, setPass] = useState("");
  const [loader, setLoader] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const validate = () => {
    let FormValid = true;

    if (!Mobile || !Pass) {
      FormValid = false;
      notify();
    } else if (
      Mobile.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/) ||
      Mobile.length !== 10
    ) {
      FormValid = false;
      mobileError();
    }
    return FormValid;
  };

  const handleLogin = async () => {
    console.log("insight API..........")
    setLoader(true);
    if (validate()) {
      let payLoad = {
        mobile: Mobile,
        password: Pass,
      };

      await axios
        .post(process.env.REACT_APP_ALL_API +"/user/login", payLoad)
        .then((res) => {
          if (res.errors === "No user found") {
            Invalid();
            setLoader(false);
          } else if (res.data.user.token) {
            sessionStorage.setItem("userInfo", JSON.stringify(res));
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          APiError();
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    const user = sessionStorage.getItem("userInfo");
    if (user) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <NavBar />
      <div className="login-main">
        <ToastContainer autoClose={false} />
        <Container className="login">
          <Row className="justify-content-center align-items-center h-100 mt-5">
            <Col md={6}>
              <Card className="card px-4 py-4 text-center">
                <h1 className="heading mb-4">Login</h1>

                <Form className="px-4">
                  <Form.Control
                    type="tel"
                    placeholder="Mobile Number"
                    value={Mobile}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
                        ) &&
                        e.target.value.length <= 10
                      ) {
                        setMobile(e.target.value);
                      }
                    }}
                    className="mb-3"
                  />

                  <InputGroup className="mb-4">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="password"
                      value={Pass}
                      onChange={(e) => setPass(e.target.value)}
                    />
                    <InputGroup.Text>
                      {showPassword ? (
                        <Icon.EyeSlash
                          style={{ cursor: "pointer" }}
                          onClick={() => setshowPassword(!showPassword)}
                        />
                      ) : (
                        <Icon.Eye
                          style={{ cursor: "pointer" }}
                          onClick={() => setshowPassword(!showPassword)}
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                  <Button
                    className="btn-submit"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLogin();
                    }}
                  >
                    {loader ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      "Login"
                    )}
                  </Button>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Login;
