import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import { Box } from "@mui/system";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import { toast, ToastContainer } from "react-toastify";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Textarea from "@mui/joy/Textarea";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const VillageDetails = () => {
   // pagination and loader
   const [loaders, setLoaders] = useState(false);
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(5);
 
   const handleChangePage = (event, newPage) => {
     setPage(newPage);
   };
   const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(+event.target.value);
     setPage(0);
   };
   const textStyle = { marginBottom: "20px" };
 
   const boxstyle = {
     position: "absolute",
     top: "50%",
     left: "50%",
     transform: "translate(-50%, -50%)",
     width: 400,
     bgcolor: "background.paper",
     boxShadow: 24,
     p: 4,
   };
 
   const villageAlreadyAdded = () => {
     toast.error("Village already added!🙂", {
       position: toast.POSITION.TOP_CENTER,
     });
   };
 
   const villageAdded = () => {
     toast.success("Village added successfully!🙂", {
       position: toast.POSITION.TOP_CENTER,
     });
   };
 
   const villageupdated = () => {
     toast.success("village updated successfully!🙂", {
       position: toast.POSITION.TOP_CENTER,
     });
   };
 
   const villagedeleted = () => {
     toast.success("village deleted successfully!🙂", {
       position: toast.POSITION.TOP_CENTER,
     });
   };
 
   const addData = () => {
     toast.error("Grampanchayat data not found, Please add Grampanchayat!🙂", {
       position: toast.POSITION.TOP_CENTER,
     });
   };
 
   const villageNotFound = () => {
     toast.error("Grampanchayat data not found, Please add Grampanchayat!🙂", {
       position: toast.POSITION.TOP_CENTER,
     });
   };
 
   const notify = () => {
     toast.error("Please enter 10 digit whatsapp number", {
       position: toast.POSITION.TOP_CENTER,
     });
   };
 
   // const Updatenotify = () => {
   //   toast.error("Please enter 10 digit whatsapp number", {
   //     position: toast.POSITION.TOP_CENTER,
   //   });
   // };
  
  //  Add village 
   const [villageData,setVillageData] = useState({
    district_name_en:"",
    taluka_name_en:"",
    grampanchayat_name_en:"",
    village_name_en:"",
    village_name_mr:""
   })

  useEffect(()=>{
    getDistrictData()
    getTalukaData()
    getGrampanchayatData()
  },[])

    //  get district data
    const [districtData,setDistrictData] = useState([])
    const getDistrictData = () => {
    axios
      .get(process.env.REACT_APP_ALL_API + "/district/getalldistricts")
      .then((res) => {
        setDistrictData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get taluka data
  const [talukaData,setTalukaData] = useState([])
  const getTalukaData = () => {
    axios
      .get(process.env.REACT_APP_ALL_API + "/taluka/getalltalukas")
      .then((res) => {
        setTalukaData(res?.data?.data);
      })
      .catch((err) => {
        console.log("Error While Get Taluka", err);
      });
  };

  // get grampanchayat data
  const [grampanchayatData,setGrampanchayatData] = useState([])
  const getGrampanchayatData= () => {
    axios
      .get(process.env.REACT_APP_ALL_API + "/grampanchayat/getallgrampanchayats")
      .then((res) => {
        setGrampanchayatData(res?.data?.data);
      })
      .catch((err) => {
        console.log("Error While Get Taluka", err);
      });
  };

  // select District,Taluka and Grampanchayat
  const [selectDTG,setSelectDTG] = useState({
    district:"",
    taluka:"",
    grampanchayat:""
  })

  const handleOnChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setSelectDTG({ ...selectDTG, [name]: value });
  };

  // filter taluka by district 
  useEffect(()=>{
    setSelectDTG({...selectDTG, taluka:"", grampanchayat: "" })
    setVillageData({...villageData,taluka_name_en:"",grampanchayat_name_en:""})
    getTalukaDataByDistrict()
  },[villageData?.district_name_en,selectDTG?.district])

  const [talukaModelData,setTalukaModelData] = useState([])
  const [talukaDataArray,setTalukaDataArray] = useState([])

  const getTalukaDataByDistrict = () => {
    const result = talukaData?.filter(
      (event) => event.district_id=== selectDTG?.district
    );
    console.log("result",result)

    const modelresult = talukaData?.filter(
      (event) => event.district_name_en === villageData?.district_name_en
    );
    console.log("modelresult",modelresult)
    setTalukaDataArray(result);
    setTalukaModelData(modelresult);
  };

  // filter grampanchayat by taluka
  useEffect(()=>{
    setSelectDTG({ ...selectDTG, grampanchayat: "" });
    setVillageData({...villageData, grampanchayat_name_en:""})
    getGrampanchayatByTaluka()
  },[villageData?.taluka_name_en,selectDTG?.taluka])

  const [grampanchayatModelData,setGrampanchayatModelData] = useState([])
  const [grampanchayatDataArray,setGrampanchayatDataArray] = useState([])

  const getGrampanchayatByTaluka = () => {
    const result = grampanchayatData?.filter(
      (event) => event.taluka_id=== selectDTG?.taluka
    );

    const modelresult = grampanchayatData?.filter(
        (event) => event.taluka_name_en === villageData?.taluka_name_en
      );
      console.log("modelresult",modelresult)
      setGrampanchayatDataArray(result);
      setGrampanchayatModelData(modelresult);
  } 

  // get village data by District,Taluka and Grampanchayat
  const [grampanchayatDataByDTG,setGrampanchayatDataByDTG] = useState([])

  useEffect(() => {
    getVillageData();
  }, []);

  useEffect(() => {
    getVillageData();
  }, [selectDTG.district, selectDTG.taluka,selectDTG.grampanchayat]);

  const getVillageData = async () => {
    const payload = {
      district_id: selectDTG.district,
      taluka_id: selectDTG.taluka,
      grampanchayat_id:selectDTG.grampanchayat
    };
    await axios
      .post(
        process.env.REACT_APP_ALL_API +
          "/village/getvillagebygrampanchayattalukadistrict",
        payload
      )
      .then((res) => {
        console.log("response-----------361", res);
        if (res.data.data === null) {
          setGrampanchayatDataByDTG(res?.data?.data);
        } else {
          setGrampanchayatDataByDTG(res?.data?.data.reverse());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

 
  // add village and open village add modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
   let name, value;
   const handleAddVillageOnChange = (e) =>{
      name = e.target.name;
      value = e.target.value;
      setVillageData({ ...villageData, [name]: value });
    };

    const addVillage = async (e) => {
      e.preventDefault();
      const payloadData = {
        district_name_en: villageData.district_name_en,
        taluka_name_en: villageData.taluka_name_en,
        grampanchayat_name_en: villageData.grampanchayat_name_en,
        village_name_en:  villageData.village_name_en.charAt(0).toUpperCase() + villageData.village_name_en.slice(1).trim(),
        village_name_mr: villageData.village_name_mr,
      };
  
      await axios
        .post(process.env.REACT_APP_ALL_API + "/village/addvillage", payloadData)
        .then((res) => {
          setVillageData({
            district_name_en:"",
            taluka_name_en:"",
            grampanchayat_name_en:"",
            village_name_en:"",
            village_name_mr:""
          })
          if (res.data.message === "Village Added Successfully") {
            setOpen(false)
            villageAdded()
            getVillageData()
          } else if (res.data.message === "Village already added") {
            villageAlreadyAdded()
            setOpen(false)
          } 
        })
        .catch((err) => {
          console.log(err);
        });
    };

  // Edit village and open Edit village model
  const [editVillage,setEditVillage] = useState({
    village_name_en:"",
    village_name_mr:"",

  })
  const [openEditModal, setOpenEditModel] = React.useState(false);
  const handleOpenEditModel = (vals) => {
    setOpenEditModel(true);
    setEditVillage({
      _id: vals?._id,
      village_name_en: vals?.village_name_en,
      village_name_mr: vals?.village_name_mr,
    });
  };
  const handleCloseEditModel = () => setOpenEditModel(false);

  const handleModelEditOnchange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setEditVillage({ ...editVillage, [name]: value });
  };

  const updateVillageData = async (e) => {
    e.preventDefault();
      const {
        _id,
        village_name_en,
        village_name_mr
      } = editVillage;
      const datas = {
        _id: _id,
        village_name_en: village_name_en.charAt(0).toUpperCase() + village_name_en.slice(1).trim(),
        village_name_mr:village_name_mr
      };
      await axios
        .post(
          process.env.REACT_APP_ALL_API + "/village/updatevillage",
          datas
        )
        .then((res) => {
          getVillageData();
          if (res?.data?.message === "Village updated successfully") {
            villageupdated();
            setOpenEditModel(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // Delete village and open Delete village model
    const [deleteVillage, setDeleteVillage] = useState({
      _id: "",
    });
    const [openDeleteModal, setOpenDeleteModel] = React.useState(false);
    const handleOpenDeleteModel = (vals) => {
      setOpenDeleteModel(true);
      setDeleteVillage({
        _id: vals?._id,
      });
    };
    const handleCloseDeleteModel = () => setOpenDeleteModel(false);

    const deleteVillageData = async () => {
      const { _id } = deleteVillage;
      const datas = {
        _id: _id,
      };
      await axios
        .post(
          process.env.REACT_APP_ALL_API + "/village/deletevillage",
          datas
        )
        .then((res) => {
          getVillageData();
          if (res.data.message === "Village deleted successfully") {
            villagedeleted();
            setOpenDeleteModel(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // view village and open village model
    const [viewVillage, setViewVillage] = useState({
      district_name_en: "",
      taluka_name_en: "",
      grampanchayat_name_en:"",
      village_name_en: "",
      village_name_mr: ""
    });
    const [openViewModal, setOpenViewModel] = React.useState(false);
    const handleOpenViewModel = (vals) => {
      setOpenViewModel(true);
      setViewVillage({
        district_name_en: vals?.district_name_en,
        taluka_name_en: vals?.taluka_name_en,
        grampanchayat_name_en: vals?.grampanchayat_name_en,
        village_name_en:vals?.village_name_en,
        village_name_mr:vals?.village_name_mr
      });
    };
    const handleCloseViewModel = () => {
      setOpenViewModel(false);
    };
  
   return (
     <>
       <ToastContainer autoClose={2000} />
       <Modal
         style={{}}
         keepMounted
         open={open}
         onClose={handleClose}
         aria-labelledby="keep-mounted-modal-title"
         aria-describedby="keep-mounted-modal-description"
       >
         <Box
           style={{
             position: "absolute",
             top: "50%",
             left: "50%",
             transform: "translate(-50%, -50%)",
             width: 450,
             bgcolor: "background.paper",
             boxShadow: 24,
             p: 4,
           }}
         >
           <Paper style={{ padding: "30px" }}>
             <Typography
               style={{
                 fontWeight: "600",
                 fontSize: "24px",
                 textAlign: "center",
                 marginBottom: "10px",
               }}
             >
               Add Village
             </Typography>
             <form onSubmit={(e) => addVillage(e)}>
               <FormControl
                 sx={{ minWidth: 390, marginBottom: "20px" }}
                 size="small"
               >
                 <InputLabel id="demo-select-small">District</InputLabel>
                 <Select
                   labelId="demo-select-small"
                   id="demo-select-small"
                   required
                   name="district_name_en"
                   value={villageData?.district_name_en}
                   label="District"
                   onChange={(e) => handleAddVillageOnChange(e)}
                 >
                   <MenuItem selected disabled>
                     <em>Select District</em>
                   </MenuItem>
                   {districtData !== undefined &&
                   districtData !== null &&
                   districtData.length > 0
                     ? districtData.map((vals, index) => {
                         return (
                           <MenuItem value={vals?.district_name_en}>
                             {vals?.district_name_en}
                           </MenuItem>
                         );
                       })
                     : ""}
                 </Select>
               </FormControl>
 
               <FormControl
                 sx={{ minWidth: 390, marginBottom: "20px" }}
                 size="small"
               >
                 <InputLabel id="demo-select-small">Taluka</InputLabel>
                 <Select
                   labelId="demo-select-small"
                   id="demo-select-small"
                   required
                   name="taluka_name_en"
                   value={villageData?.taluka_name_en}
                   label="Taluka"
                   onChange={(e) => handleAddVillageOnChange(e)}
                 >
                   <MenuItem selected disabled>
                     <em>Select Taluka</em>
                   </MenuItem>
                   {talukaModelData !== undefined &&
                   talukaModelData !== null &&
                   talukaModelData.length > 0
                     ? talukaModelData.map((vals, index) => {
                         return (
                           <MenuItem value={vals?.taluka_name_en}>
                             {vals?.taluka_name_en}
                           </MenuItem>
                         );
                       })
                     : ""}
                 </Select>
               </FormControl>

               <FormControl
                 sx={{ minWidth: 390, marginBottom: "20px" }}
                 size="small"
               >
                 <InputLabel id="demo-select-small">Grampanchayat</InputLabel>
                 <Select
                   labelId="demo-select-small"
                   id="demo-select-small"
                   required
                   name="grampanchayat_name_en"
                   value={villageData?.grampanchayat_name_en}
                   label="Grampanchayat"
                   onChange={(e) => handleAddVillageOnChange(e)}
                 >
                   <MenuItem selected disabled>
                     <em>Select Grampanchayat</em>
                   </MenuItem>
                   {grampanchayatModelData !== undefined &&
                    grampanchayatModelData !== null &&
                    grampanchayatModelData.length > 0
                     ? grampanchayatModelData.map((vals, index) => {
                         return (
                           <MenuItem value={vals?.grampanchayat_name_en}>
                             {vals?.grampanchayat_name_en}
                           </MenuItem>
                         );
                       })
                     : ""}
                 </Select>
               </FormControl>
               
               <TextField
                 label="Village"
                 variant="outlined"
                 placeholder="Enter village name"
                 fullWidth
                 required
                 size="small"
                 name="village_name_en"
                 style={textStyle}
                 value={villageData?.village_name_en}
                 onChange={(e) => {
                   if (
                     !e.target.value.match(
                       /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                     ) &&
                     e.target.value.trim()
                   ) {
                     handleAddVillageOnChange(e);
                   } else if (e.target.value.length === 0) {
                     handleAddVillageOnChange(e);
                   }
                 }}
               />
               <TextField
                 label="गाव"
                 variant="outlined"
                 placeholder="गाव प्रविष्ट करा"
                 fullWidth
                 required
                 size="small"
                 name="village_name_mr"
                 style={textStyle}
                 value={villageData?.village_name_mr}
                 onChange={(e) => {
                   if (
                     e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                     e.target.value.trim()
                   ) {
                     handleAddVillageOnChange(e);
                   } else if (e.target.value.length === 0) {
                     handleAddVillageOnChange(e);
                   }
                 }}
               />
               <Button
                 type="submit"
                 variant="contained"
                 fullWidth
                 sx={{
                   backgroundColor: "#a8c74d",
                   padding: "10px 0px",
                   "&:hover": {
                     color: "#a8c74d",
                     backgroundColor: "white",
                     transition: "all 0.2s",
                   },
                 }}
               >
                 Add
               </Button>
             </form>
           </Paper>
         </Box>
       </Modal>
 
       <Modal
         style={{}}
         keepMounted
         open={openEditModal}
         onClose={handleCloseEditModel}
         aria-labelledby="keep-mounted-modal-title"
         aria-describedby="keep-mounted-modal-description"
       >
         <Box style={boxstyle}>
           <Paper style={{ padding: "30px" }}>
             <Typography
               style={{
                 fontWeight: "600",
                 fontSize: "24px",
                 textAlign: "center",
                 marginBottom: "25px",
               }}
             >
               Update village
             </Typography>
             <form onSubmit={(e) => updateVillageData(e)}>
               <TextField
                 label="Village"
                 variant="outlined"
                 fullWidth
                 required
                 size="small"
                 name="village_name_en"
                 style={textStyle}
                 value={editVillage?.village_name_en}
                 onChange={(e) => {
                   if (
                     !e.target.value.match(
                       /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                     ) &&
                     e.target.value.trim()
                   ) {
                     handleModelEditOnchange(e);
                   } else if (e.target.value.length === 0) {
                     handleModelEditOnchange(e);
                   }
                 }}
               />
               <TextField
                 label="ग्रामपंचायत"
                 variant="outlined"
                 fullWidth
                 required
                 size="small"
                 name="village_name_mr"
                 style={textStyle}
                 value={editVillage?.village_name_mr}
                 onChange={(e) => {
                   if (
                     e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                     e.target.value.trim()
                   ) {
                     handleModelEditOnchange(e);
                   } else if (e.target.value.length === 0) {
                     handleModelEditOnchange(e);
                   }
                 }}
               />
               <Button
                 type="submit"
                 variant="contained"
                 fullWidth
                 sx={{
                   backgroundColor: "#a8c74d",
                   padding: "10px 0px",
                   "&:hover": {
                     color: "#a8c74d",
                     backgroundColor: "white",
                     transition: "all 0.2s",
                   },
                 }}
               >
                 Update
               </Button>
             </form>
           </Paper>
         </Box>
       </Modal>
 
       <Modal
         style={{}}
         keepMounted
         open={openViewModal}
         onClose={handleCloseViewModel}
         aria-labelledby="keep-mounted-modal-title"
         aria-describedby="keep-mounted-modal-description"
       >
         <Box style={boxstyle}>
           <Paper style={{ padding: "30px" }}>
             <Typography
               style={{
                 fontWeight: "600",
                 fontSize: "24px",
                 textAlign: "center",
                 marginBottom: "25px",
               }}
             >
               View village data
             </Typography>
             <TextField
               label="District"
               variant="outlined"
               fullWidth
               size="small"
               name="district_name_en"
               style={textStyle}
               value={viewVillage?.district_name_en}
             />
             <TextField
               label="Taluka"
               variant="outlined"
               fullWidth
               size="small"
               name="taluka_name_en"
               style={textStyle}
               value={viewVillage?.taluka_name_en}
             />
             <TextField
               label="Grampanchayat"
               variant="outlined"
               fullWidth
               size="small"
               name="grampanchayat_name_en"
               style={textStyle}
               value={viewVillage?.grampanchayat_name_en}
             />
             <TextField
               label="Village"
               variant="outlined"
               fullWidth
               required
               size="small"
               name="village_name_en"
               style={textStyle}
               value={viewVillage?.village_name_en}
             />
             <TextField
               label="गाव"
               variant="outlined"
               fullWidth
               required
               size="small"
               name="village_name_mr"
               style={textStyle}
               value={viewVillage?.village_name_mr}
             />
           </Paper>
         </Box>
       </Modal>
 
       <Modal
         keepMounted
         open={openDeleteModal}
         onClose={handleCloseDeleteModel}
         aria-labelledby="keep-mounted-modal-title"
         aria-describedby="keep-mounted-modal-description"
       >
         <Box style={boxstyle}>
           <Paper
             style={{
               padding: "25px",
             }}
           >
             <Typography
               style={{ textAlign: "center", margin: "10px" }}
               variant="h6"
             >
               Are you sure You want to delete?
             </Typography>
             <Typography
               style={{ display: "flex", justifyContent: "space-between" }}
             >
               <Button
                 variant="outlined"
                 size="small"
                 style={{ backgroundColor: "green", color: "white" }}
                 onClick={handleCloseDeleteModel}
               >
                 No
               </Button>
               <Button
                 variant="outlined"
                 style={{ backgroundColor: "red", color: "white" }}
                 onClick={deleteVillageData}
               >
                 Yes
               </Button>
             </Typography>
           </Paper>
         </Box>
       </Modal>
 
       <Paper
         elevation={1}
         sx={{
           width: "100%",
           marginBottom: "10px",
           height: "60px",
         }}
       >
         <div style={{ display: "flex", justifyContent: "space-between" }}>
           <div
             style={{
               display: "flex",
             }}
           >
             <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
               <InputLabel id="demo-select-small">District</InputLabel>
               <Select
                 labelId="demo-select-small"
                 id="demo-select-small"
                 name="district"
                 value={selectDTG?.district}
                 label="District"
                 onChange={(e) => handleOnChange(e)}
               >
                 <MenuItem selected disabled>
                   <em>Select District</em>
                 </MenuItem>
                 {districtData !== undefined &&
                 districtData !== null &&
                 districtData.length > 0
                   ? districtData.map((vals, index) => {
                       return (
                         <MenuItem value={vals?._id}>
                           {vals?.district_name_en}
                         </MenuItem>
                       );
                     })
                   : ""}
               </Select>
             </FormControl>
             <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
               <InputLabel id="demo-select-small">Taluka</InputLabel>
               <Select
                 labelId="demo-select-small"
                 id="demo-select-small"
                 name="taluka"
                 value={selectDTG?.taluka}
                 label="Taluka"
                 onChange={(e) => handleOnChange(e)}
               >
                 <MenuItem selected disabled>
                   <em>Select Taluka</em>
                 </MenuItem>
                 {talukaDataArray !== undefined &&
                 talukaDataArray !== null &&
                 talukaDataArray.length > 0
                   ? talukaDataArray.map((vals, index) => {
                       return (
                         <MenuItem value={vals?._id}>
                           {vals?.taluka_name_en}
                         </MenuItem>
                       );
                     })
                   : ""}
               </Select>
             </FormControl>
             <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
               <InputLabel id="demo-select-small">Grampnachayat</InputLabel>
               <Select
                 labelId="demo-select-small"
                 id="demo-select-small"
                 name="grampanchayat"
                 value={selectDTG?.grampanchayat}
                 label="Grampanchayat"
                 onChange={(e) => handleOnChange(e)}
               >
                 <MenuItem selected disabled>
                   <em>Select Grampanchayat</em>
                 </MenuItem>
                 {grampanchayatDataArray !== undefined &&
                 grampanchayatDataArray !== null &&
                 grampanchayatDataArray.length > 0
                   ? grampanchayatDataArray?.map((vals, index) => {
                       return (
                         <MenuItem value={vals?._id}>
                           {vals?.grampanchayat_name_en}
                         </MenuItem>
                       );
                     })
                   : ""}
               </Select>
             </FormControl>
           </div>
           <div style={{ padding: "10px" }}>
             <Button
               sx={{
                 color: "white",
                 backgroundColor: "#a8c74d",
                 "&:hover": {
                   backgroundColor: "#c9ec60",
                   color: "white",
                   transition: "all 0.2s",
                 },
               }}
               onClick={handleOpen}
             >
               Add Village
               <AddBoxOutlined />
             </Button>
           </div>
         </div>
       </Paper>
 
       <Paper
         sx={{
           width: "100%",
           overflow: "hidden",
           margin: "10px auto",
           padding: "5px 0px",
         }}
       >
         <TableContainer sx={{ maxHeight: 400 }}>
           <Table stickyHeader aria-label="sticky table">
             <TableHead
               hover
               className="tablehead"
               sx={{
                 backgroundColor: "#a8c74d",
               }}
             >
               <TableRow>
                 <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                   Sr.No
                 </TableCell>
                 <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                   District
                 </TableCell>
                 <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                   Taluka
                 </TableCell>
                 <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                   Grampanchayat
                 </TableCell>
                 <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                   Village Name English
                 </TableCell>
                 <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Village Name Marathi
                 </TableCell>
                 <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                   Edit
                 </TableCell>
                 <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                   View
                 </TableCell>
                 <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                   Delete
                 </TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {loaders ? (
                 <div
                   style={{
                     width: "100%",
                   }}
                 >
                   <div
                     class="spinner-border"
                     role="status"
                     style={{ display: "flex", justifyContent: "center" }}
                   ></div>
 
                   <span class="sr-only"></span>
                 </div>
               ) : grampanchayatDataByDTG !== undefined &&
                 grampanchayatDataByDTG !== null &&
                 grampanchayatDataByDTG.length > 0 ? (
                 grampanchayatDataByDTG
                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                   .map((vals, index) => {
                     return (
                       <TableRow hover>
                         {page !== 0 ? (
                           <TableCell>
                             {index + 1 + rowsPerPage * page}
                           </TableCell>
                         ) : (
                           <TableCell>{index + 1}</TableCell>
                         )}
                         <TableCell>{vals?.district_name_en}</TableCell>
                         <TableCell>{vals?.taluka_name_en}</TableCell>
                         <TableCell>{vals?.grampanchayat_name_en}</TableCell>
                         <TableCell>{vals?.village_name_en}</TableCell>
                         <TableCell>{vals?.village_name_mr}</TableCell>
                         <TableCell>
                           <EditOutlinedIcon
                             onClick={() => handleOpenEditModel(vals)}
                             sx={{
                               color: "white",
                               backgroundColor: "#a8c74d",
                               borderRadius: "50%",
                               padding: "2px",
                               "&:hover": {
                                 backgroundColor: "#c9ec60",
                                 color: "white",
                                 transition: "all 0.2s",
                               },
                             }}
                           />
                         </TableCell>
                         <TableCell>
                           <ViewListOutlinedIcon
                             onClick={() => handleOpenViewModel(vals)}
                             sx={{
                               color: "white",
                               backgroundColor: "#a8c74d",
                               borderRadius: "50%",
                               padding: "2px",
                               "&:hover": {
                                 backgroundColor: "#c9ec60",
                                 color: "white",
                                 transition: "all 0.2s",
                               },
                             }}
                           />
                         </TableCell>
                         <TableCell>
                           <DeleteOutlined
                             onClick={() => handleOpenDeleteModel(vals)}
                             sx={{
                               color: "white",
                               backgroundColor: "#a8c74d",
                               borderRadius: "50%",
                               padding: "2px",
 
                               "&:hover": {
                                 backgroundColor: "#c9ec60",
                                 color: "white",
                                 transition: "all 0.2s",
                               },
                             }}
                           />
                         </TableCell>
                       </TableRow>
                     );
                   })
               ) : (
                 "Data not found"
               )}
             </TableBody>
           </Table>
         </TableContainer>
         {grampanchayatDataByDTG !== null &&
         grampanchayatDataByDTG !== undefined &&
         grampanchayatDataByDTG.length > 0 ? (
           <TablePagination
             component="div"
             rowsPerPageOptions={[5, 10, 25, 100]}
             count={
               grampanchayatDataByDTG === null &&
               grampanchayatDataByDTG === undefined
                 ? 0
                 : grampanchayatDataByDTG
                 ? grampanchayatDataByDTG.length
                 : 0
             }
             rowsPerPage={rowsPerPage}
             page={page}
             onPageChange={handleChangePage}
             onRowsPerPageChange={handleChangeRowsPerPage}
           />
         ) : (
           ""
         )}
       </Paper>
     </>
   );
}
