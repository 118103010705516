import "./App.css";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import MainLayout from "./components/MainLayout";
// import Section from '../src/components/Section';
import SectionOld from "./components/SectionOld";
import Login from "./components/Login";
import NewRecords from "./components/NewRecords";
import AdminLayout from "./Layout/AdminLayout";
import AdminHome from "./components/Admin/AdminHome";
import SearchAndDropdown from "./components/Admin/SearchAndDropdownCopy";
import UserForm from "./components/UserForm";
import { useEffect, useState } from "react";
import SendMsg from "./components/SendMsg";
import ViewUserDetails from "./components/Admin/ViewUserDetails";
import DistrictDetails from "./components/Admin/DistrictDetails";
import TalukaDetails from "./components/Admin/TalukaDetails";
import GrampanchayatDetails from "./components/Admin/GrampanchayatDetails";
import PrivateRoute from "./components/Admin/auth/PrivateRoutes";
import { AddDetailsForm } from "./components/Admin/AddDetailsForm";
import SearchAndDropdownCopy from "./components/Admin/SearchAndDropdownCopy";
import PrivateRoutes from "./components/Admin/auth/PrivateRoutes";
import { VillageDetails } from "./components/Admin/VillageDetails";

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/") {
      navigate("/Pune/Tung");
    }
  }, []);
  return (
    <div>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {/* <Route path='/' element={<Section/>} /> */}
          <Route path="/:district/:grampanchayat" element={<SectionOld />} />
          <Route path="/:district" element={<SectionOld />} />
          <Route path="/home" element={<UserForm />} />
          {/* <Route path="/home" element={<SendMsg />} /> */}
          {/* <Route path='/admin/records' element={<Records/>} /> */}
        </Route>

        <Route path="/login" element={<Login />}></Route>

        <Route
          path="/dashboard"
          element={
            <PrivateRoutes>
              <AdminLayout />
            </PrivateRoutes>
          }
        >
          <Route
            path="/dashboard"
            element={
              <PrivateRoutes>
                <AdminHome />
              </PrivateRoutes>
            }
          />
          <Route
            path="/dashboard/records"
            element={
              <PrivateRoutes>
                <NewRecords />
              </PrivateRoutes>
            }
          />
          <Route
            path="/dashboard/CreateUser"
            element={
              <PrivateRoutes>
                <SearchAndDropdownCopy />
                {/* <AddDetailsForm/> */}
              </PrivateRoutes>
            }
          />
          <Route
            path="/dashboard/userDetails"
            element={
              <PrivateRoutes>
                <ViewUserDetails />
              </PrivateRoutes>
            }
          />
          <Route
            path="/dashboard/DistrictDetails"
            element={
              <PrivateRoutes>
                <DistrictDetails />
              </PrivateRoutes>
            }
          />
          <Route
            path="/dashboard/TalukaDeatils"
            element={
              <PrivateRoutes>
                <TalukaDetails />
              </PrivateRoutes>
            }
          />
          <Route
            path="/dashboard/GrampanchayatDetails"
            element={
              <PrivateRoutes>
                <GrampanchayatDetails />
              </PrivateRoutes>
            }
          />
          <Route
            path="/dashboard/VillageDetails"
            element={
              <PrivateRoutes>
                <VillageDetails/>
              </PrivateRoutes>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
