import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Textarea from "@mui/joy/Textarea/Textarea";

const SearchAndDropdownCopy = () => {
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [grampanchayats, setGrampanchayats] = useState([]);

  const [districtInput, setDistrictInput] = useState("");
  const [talukaInput, setTalukaInput] = useState("");
  const [grampanchyatInput, setGrampanchyatInput] = useState("");

  const [villageNameEng, setVillageNameEng] = useState("");
  const [villageNameMr, setVillageNameMr] = useState("");

  const textStyle = { marginBottom: "20px" };
  const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  // toast message
  const districtAddedSuccessfully = () => {
    toast.success("District Added Successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const districtAlreadyAdded = () => {
    toast.error("District already added!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  useEffect(() => {
    getTaluka();
  }, []);

  const [talukaDatas, setTalukaDatas] = useState([]);
  // get all talukas
  const getTaluka = async () => {
    await axios
      .get(process.env.REACT_APP_ALL_API + "/taluka/getalltalukas")

      .then((res) => {
        setTalukaDatas(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //ADD DISTRICT DATA
  const [addDistrictData, setAddDistrictData] = useState({
    district_name_mr: "",
    district_name_en: "",
  });
  const [newDistrictData, setNewDistrictData] = useState([]);

  let name, value;
  const handleAddDistrictOnChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setAddDistrictData({ ...addDistrictData, [name]: value });
  };

  const createNewDistrict = (e) => {
    e.preventDefault();
    const payloadData = {
      district_name_mr: addDistrictData.district_name_mr.trim(),
      district_name_en:
        addDistrictData.district_name_en.charAt(0).toUpperCase() +
        addDistrictData.district_name_en.slice(1).trim(),
    };
    axios
      .post(
        process.env.REACT_APP_ALL_API + "/district/adddistrict",
        payloadData
      )
      .then((res) => {
        setNewDistrictData(res.data.data);
        setAddDistrictData({
          district_name_mr: "",
          district_name_en: "",
        });
        if (res.data.message === "District already added") {
          districtAlreadyAdded();
          setOpen(false);
        } else if (res.data.message === "District Added Successfully") {
          districtAddedSuccessfully();
          setDistricts([...districts, res.data.data]);
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const talukaAddedSuccess = () => {
    toast.success("Taluka Added Successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const TalukaAlreadyAdded = () => {
    toast.error("Taluka already added!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // ADD TALUKA DATA
  const [addTalukaData, setAddTalukaData] = useState({
    district_name_en: "",
    taluka_name_en: "",
    taluka_name_mr: "",
  });

  const [newTalukaData, setNewTalukaData] = useState([]);

  const handleTalukaDataOnChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setAddTalukaData({ ...addTalukaData, [name]: value });
  };
  const createNewTaluka = (e) => {
    e.preventDefault();
    axios
      .post(process.env.REACT_APP_ALL_API + "/taluka/addtaluka", {
        district_name_en: addTalukaData.district_name_en,
        taluka_name_mr: addTalukaData.taluka_name_mr.trim(),
        taluka_name_en:
          addTalukaData.taluka_name_en.charAt(0).toUpperCase() +
          addTalukaData.taluka_name_en.slice(1).trim(),
      })
      .then((res) => {
        setAddTalukaData({
          district_name_en: "",
          taluka_name_en: "",
          taluka_name_mr: "",
        });
        if (res.data.message === "Taluka already added") {
          TalukaAlreadyAdded();
          setOpenTalModel(false);
        } else if (res.data.message === "Taluka Added Successfully") {
          talukaAddedSuccess();
          setTalukaDatas([...talukaDatas, res.data.data]);
          setOpenTalModel(false);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const grampanchayatAddedSuccess = () => {
    toast.success("Grampanchayat added successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const grampanchayatAlreadyAdded = () => {
    toast.error("Grampanchayat already added!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  //   ADD GRAMPANCHAYAT
  const [addGrampanachayatData, setAddGrampanchayatData] = useState({
    district_name_en: "",
    taluka_name_en: "",
    grampanchayat_name_en: "",
    grampanchayat_name_mr: "",
    whatsapp_number: "",
    whatsapp_message_en: "",
    whatsapp_message_mr: "",
  });

  const handleGrampanchayatDataOnChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setAddGrampanchayatData({ ...addGrampanachayatData, [name]: value });
  };
  const createNewGrampanchayat = (e) => {
    e.preventDefault();
    if (validate()) {
      axios
        .post(
          process.env.REACT_APP_ALL_API + "/grampanchayat/addgrampanchayat",
          {
            district_name_en: addGrampanachayatData.district_name_en,
            taluka_name_en: addGrampanachayatData.taluka_name_en,
            grampanchayat_name_en:
              addGrampanachayatData.grampanchayat_name_en
                .charAt(0)
                .toUpperCase() +
              addGrampanachayatData.grampanchayat_name_en.slice(1).trim(),
            grampanchayat_name_mr:
              addGrampanachayatData.grampanchayat_name_mr.trim(),
            whatsapp_number: addGrampanachayatData.whatsapp_number,
            whatsapp_message_en: addGrampanachayatData.whatsapp_message_en,
            whatsapp_message_mr: addGrampanachayatData.whatsapp_message_mr,
          }
        )
        .then((res) => {
          setAddGrampanchayatData({
            district_name_en: "",
            taluka_name_en: "",
            grampanchayat_name_en: "",
            grampanchayat_name_mr: "",
            whatsapp_number: "",
            whatsapp_message_en: "",
            whatsapp_message_mr: "",
          });
          if (res.data.message === "Grampanchayat already added") {
            grampanchayatAlreadyAdded();
            setOpenGrampanchayatModel(false);
          } else if (res.data.message === "Grampanchayat Added Successfully") {
            grampanchayatAddedSuccess();
            setGrampanchayats([...grampanchayats, res.data.data]);
            setOpenGrampanchayatModel(false);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  const [filteredTal, setFilteredTal] = useState([]);

  useEffect(() => {
    setAddGrampanchayatData({ ...addGrampanachayatData, taluka_name_en: "" });
    filteredTalukas();
  }, [addGrampanachayatData?.district_name_en]);

  const filteredTalukas = () => {
    const results =
      talukaDatas?.length > 0 &&
      talukaDatas?.filter(
        (event) =>
          event?.district_name_en === addGrampanachayatData?.district_name_en
      );
    setFilteredTal(results);
  };

  //Calling district ,taluka and grampanchyat
  const [districtId, setDistrictId] = useState();
  const [getTalukaByDistrict, setGetTalukaByDistrict] = useState([]);

  const getDist = async () => {
    await axios
      .get(process.env.REACT_APP_ALL_API + "/district/getalldistricts")
      .then((res) => {
        setDistricts(res.data.data);
        setDistrictId(res.data.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTalukaByDistrictIdModel();
  }, []);

  const getTalukaByDistrictIdModel = async () => {
    await axios
      .post(process.env.REACT_APP_ALL_API + "/taluka/gettalukabydistrictid", {
        district_id: districtId,
      })
      .then((res) => {
        setGetTalukaByDistrict(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTalukasByDistrictId = useCallback(
    (district_id) => {
      if (district_id !== null || district_id) {
        axios
          .post(
            process.env.REACT_APP_ALL_API + "/taluka/gettalukabydistrictid",
            {
              district_id: district_id.value,
            }
          )
          .then((res) => {
            setTalukas([]);
            setTalukaInput("");
            setGrampanchayats([]);
            setGrampanchyatInput("");
            setTalukas(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setTalukas([]);
        setTalukaInput("");
        setGrampanchayats([]);
        setGrampanchyatInput("");
      }
    },
    [districtInput]
  );

  const getGrampanchayatByTalukaIdAndDistrictId = useCallback(
    (district_id, taluka_id) => {
      if (taluka_id !== null || taluka_id) {
        axios
          .post(
            process.env.REACT_APP_ALL_API +
              "/grampanchayat/getgrampanchayatbytalukaid",
            {
              district_id: district_id,
              taluka_id: taluka_id.value,
            }
          )
          .then((res) => {
            setGrampanchayats([]);
            setGrampanchyatInput("");
            setGrampanchayats(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setGrampanchayats([]);
        setGrampanchyatInput("");
      }
    },
    [talukaInput]
  );

  useEffect(() => {
    getDist();
    // getTalukasByDistrictId(districtInput ? districtInput.value : "");
  }, []);

  const districtOptions =
    districts.length > 0 &&
    districts !== undefined &&
    districts !== null &&
    districts.map((itm) => {
      return { value: itm._id, label: itm.district_name_en };
    });

  const villageAdded = () => {
    toast.success("Village added successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const villageAlreadyAdded = () => {
    toast.error("Village already added!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const villageNameEnglish = () => {
    toast.error("Please enter village name english!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const whatsappNumberNotify = () => {
    toast.error("Please enter 10 digit whatsapp number!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const validate = () => {
    let FormValid = true;
    if (
      addGrampanachayatData?.whatsapp_number.match(
        /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
      ) ||
      addGrampanachayatData?.whatsapp_number.length !== 10
    ) {
      FormValid = false;
      whatsappNumberNotify();
    }
    return FormValid;
  };

  // open add district modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // open add taluka model
  const [openTalModel, setOpenTalModel] = React.useState(false);
  const handleOpenTalModel = (e) => {
    setOpenTalModel(true);
  };
  const handleCloseTalModel = () => setOpenTalModel(false);

  // open add grampanchayat model
  const [openGrampanchayatModel, setOpenGrampanchayatModel] =
    React.useState(false);
  const handleOpenGrampanchayatModel = (e) => {
    setOpenGrampanchayatModel(true);
  };
  const handleCloseGrampanchayatModel = () => setOpenGrampanchayatModel(false);

  // handle form submit
  const postDatas = async (e) => {
    e.preventDefault();

    const payloadData = {
      district_name_en: districtInput.label,
      taluka_name_en: talukaInput.label,
      grampanchayat_name_en: grampanchyatInput.label,
      village_name_en: villageNameEng,
      village_name_mr: villageNameMr,
    };

    await axios
      .post(process.env.REACT_APP_ALL_API + "/village/addvillage", payloadData)
      .then((res) => {
        if (res.data.message === "Village Added Successfully") {
          villageAdded();
          setDistricts([]);
          setDistrictInput("");
          setTalukas([]);
          setTalukaInput("");
          setGrampanchayats([]);
          setGrampanchyatInput("");
          setVillageNameEng("");
          setVillageNameMr("");
          getDist();
        } else if (res.data.message === "Village already added") {
          villageAlreadyAdded();
          setVillageNameEng("");
          setVillageNameMr("");
        } else if (res.data.message === "Please enter village name english.") {
          villageNameEnglish();
          setVillageNameEng("");
          setVillageNameMr("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        style={{}}
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "20px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "20px",
                textAlign: "center",
                marginBottom: "15px",
              }}
            >
              Add District
            </Typography>
            <form onSubmit={(e) => createNewDistrict(e)}>
              <TextField
                label="District"
                variant="outlined"
                placeholder="Enter district name here..."
                fullWidth
                required
                size="small"
                name="district_name_en"
                style={textStyle}
                value={addDistrictData?.district_name_en}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    handleAddDistrictOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleAddDistrictOnChange(e);
                  }
                }}
              />

              <TextField
                label="जिल्हा"
                variant="outlined"
                placeholder="जिल्ह्याचे नाव प्रविष्ट करा..."
                fullWidth
                required
                size="small"
                name="district_name_mr"
                style={textStyle}
                value={addDistrictData?.district_name_mr}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                    e.target.value.trim()
                  ) {
                    handleAddDistrictOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleAddDistrictOnChange(e);
                  }
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#a8c74d",
                  padding: "10px 0px",
                  "&:hover": {
                    color: "#a8c74d",
                    backgroundColor: "white",
                    transition: "all 0.2s",
                  },
                }}
              >
                Add
              </Button>
            </form>
          </Paper>
        </Box>
      </Modal>

      <Modal
        style={{}}
        keepMounted
        open={openTalModel}
        onClose={handleCloseTalModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "20px",
                textAlign: "center",
                marginBottom: "15px",
              }}
            >
              Add Taluka
            </Typography>
            <form onSubmit={(e) => createNewTaluka(e)}>
              <FormControl
                sx={{ minWidth: 340, marginBottom: "25px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">District</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="district_name_en"
                  required
                  value={addTalukaData?.district_name_en}
                  label="District"
                  onChange={(e) => handleTalukaDataOnChange(e)}
                >
                  <MenuItem selected disabled>
                    <em>Select District</em>
                  </MenuItem>
                  {districts !== undefined &&
                  districts !== null &&
                  districts.length > 0
                    ? districts.map((vals, index) => {
                        return (
                          <MenuItem value={vals?.district_name_en}>
                            {vals?.district_name_en}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>
              <TextField
                label="Taluka"
                variant="outlined"
                placeholder="Enter taluka name here..."
                fullWidth
                required
                size="small"
                name="taluka_name_en"
                style={textStyle}
                value={addTalukaData?.taluka_name_en}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    handleTalukaDataOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleTalukaDataOnChange(e);
                  }
                }}
              />
              <TextField
                label="तालुका"
                variant="outlined"
                placeholder="तालुक्याचे नाव प्रविष्ट करा..."
                fullWidth
                required
                size="small"
                name="taluka_name_mr"
                style={textStyle}
                value={addTalukaData?.taluka_name_mr}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                    e.target.value.trim()
                  ) {
                    handleTalukaDataOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleTalukaDataOnChange(e);
                  }
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#a8c74d",
                  padding: "10px 0px",
                  "&:hover": {
                    color: "#a8c74d",
                    backgroundColor: "white",
                    transition: "all 0.2s",
                  },
                }}
              >
                Add
              </Button>
            </form>
          </Paper>
        </Box>
      </Modal>

      <Modal
        style={{}}
        keepMounted
        open={openGrampanchayatModel}
        onClose={handleCloseGrampanchayatModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "20px",
                textAlign: "center",
                marginBottom: "15px",
              }}
            >
              Add Grampanchayat
            </Typography>
            <form onSubmit={(e) => createNewGrampanchayat(e)}>
              <FormControl
                sx={{ minWidth: 340, marginBottom: "25px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">District</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  required
                  name="district_name_en"
                  value={addGrampanachayatData?.district_name_en}
                  label="District"
                  onChange={(e) => {
                    handleGrampanchayatDataOnChange(e);
                    // getTalukaDatas(e);
                  }}
                >
                  <MenuItem selected disabled>
                    <em>Select District</em>
                  </MenuItem>
                  {districts !== undefined &&
                  districts !== null &&
                  districts.length > 0
                    ? districts.map((vals, index) => {
                        return (
                          <MenuItem value={vals?.district_name_en}>
                            {vals?.district_name_en}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>
              <FormControl
                sx={{ minWidth: 340, marginBottom: "25px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">Taluka</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  required
                  name="taluka_name_en"
                  value={addGrampanachayatData?.taluka_name_en}
                  label="Taluka"
                  onChange={(e) => handleGrampanchayatDataOnChange(e)}
                >
                  <MenuItem selected disabled>
                    <em>Select Taluka</em>
                  </MenuItem>
                  {filteredTal !== undefined &&
                  filteredTal !== null &&
                  filteredTal.length > 0
                    ? filteredTal.map((vals, index) => {
                        return (
                          <MenuItem value={vals?.taluka_name_en}>
                            {vals?.taluka_name_en}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>
              <TextField
                label="Grampanchayat"
                variant="outlined"
                placeholder="Enter grampanchayat name here..."
                fullWidth
                required
                size="small"
                name="grampanchayat_name_en"
                style={textStyle}
                value={addGrampanachayatData?.grampanchayat_name_en}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    handleGrampanchayatDataOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleGrampanchayatDataOnChange(e);
                  }
                }}
              />
              <TextField
                label="ग्रामपंचायत"
                variant="outlined"
                placeholder="ग्रामपंचायत प्रविष्ट करा..."
                fullWidth
                required
                size="small"
                name="grampanchayat_name_mr"
                style={textStyle}
                value={addGrampanachayatData?.grampanchayat_name_mr}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                    e.target.value.trim()
                  ) {
                    handleGrampanchayatDataOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleGrampanchayatDataOnChange(e);
                  }
                }}
              />
              <TextField
                label="Whatsapp Number"
                variant="outlined"
                placeholder="Enter Whatsaap number"
                fullWidth
                required
                size="small"
                name="whatsapp_number"
                style={textStyle}
                value={addGrampanachayatData?.whatsapp_number}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.length <= 10
                  ) {
                    handleGrampanchayatDataOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleGrampanchayatDataOnChange(e);
                  }
                }}
              />
              <Textarea
                label="message"
                variant="outlined"
                placeholder="Enter Whatsaap message here..."
                fullWidth
                required
                minRows={2}
                // size="sm"
                name="whatsapp_message_en"
                style={textStyle}
                value={addGrampanachayatData?.whatsapp_message_en}
                onChange={(e) => {
                  handleGrampanchayatDataOnChange(e);
                }}
              />
              <Textarea
                label="संदेश"
                variant="outlined"
                placeholder="कृपया संदेश प्रविष्ट करा..."
                fullWidth
                required
                minRows={2}
                // size="sm"
                name="whatsapp_message_mr"
                style={textStyle}
                value={addGrampanachayatData?.whatsapp_message_mr}
                onChange={(e) => {
                  if (
                    !e.target.value.match(/^[\u0900-\u097F ]+$/) &&
                    e.target.value.trim()
                  ) {
                    handleGrampanchayatDataOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleGrampanchayatDataOnChange(e);
                  }
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#a8c74d",
                  padding: "10px 0px",
                  "&:hover": {
                    color: "#a8c74d",
                    backgroundColor: "white",
                    transition: "all 0.2s",
                  },
                }}
              >
                Add
              </Button>
            </form>
          </Paper>
        </Box>
      </Modal>

      <Paper
        className="paper-wrapper"
        sx={{ width: "60%", margin: "5px auto", padding: "5px 50px" }}
      >
        <Form id="test" onSubmit={(e) => postDatas(e)}>
          <div
            className="form-input"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="input-field ">
              <div>
                <Autocomplete
                  className="mt-4"
                  id="combo-box-demo"
                  size="small"
                  options={districtOptions ? districtOptions : []}
                  value={districtInput}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="District" required />
                  )}
                  onChange={(event, newValue) => {
                    setDistrictInput(newValue);
                    getTalukasByDistrictId(newValue);
                  }}
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  href="#contained-buttons"
                  onClick={handleOpen}
                  sx={{
                    backgroundColor: "#a8c74d",
                    marginTop: "8px",
                    marginLeft: "10px",
                    "&:hover": {
                      color: "#a8c74d",
                      backgroundColor: "white",
                    },
                  }}
                >
                  Add
                </Button>
              </div>
            </div>

            <div className="input-field">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="form-input">
                  <div style={{ width: "100%" }}>
                    <Autocomplete
                      className="mt-4"
                      size="small"
                      id="combo-box-demo"
                      value={talukaInput}
                      options={
                        talukas && talukas.length > 0
                          ? talukas.map((itm) => {
                              return {
                                value: itm._id,
                                label: itm.taluka_name_en,
                              };
                            })
                          : []
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Taluka" required />
                      )}
                      onChange={(event, newValue) => {
                        setTalukaInput(newValue);
                        getGrampanchayatByTalukaIdAndDistrictId(
                          districtInput.value,
                          newValue
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    href="#contained-buttons"
                    onClick={handleOpenTalModel}
                    sx={{
                      backgroundColor: "#a8c74d",
                      marginTop: "8px",
                      marginTop: "25px",
                      marginLeft: "10px",
                      "&:hover": {
                        color: "#a8c74d",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>

            <div className="input-field">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="form-input">
                  <div className="d-flex flex-column" style={{ width: "100%" }}>
                    <Autocomplete
                      className="mt-4 ml3"
                      fullWidth
                      size="small"
                      id="combo-box-demo"
                      value={grampanchyatInput}
                      options={
                        grampanchayats && grampanchayats.length > 0
                          ? grampanchayats.map((itm) => {
                              return {
                                value: itm._id,
                                label: itm.grampanchayat_name_en,
                              };
                            })
                          : []
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Grampanchayat" required />
                      )}
                      onChange={(event, newValue) => {
                        setGrampanchyatInput(newValue);
                      }}
                    />
                  </div>
                </div>

                <div>
                  <Button
                    variant="contained"
                    href="#contained-buttons"
                    onClick={handleOpenGrampanchayatModel}
                    sx={{
                      backgroundColor: "#a8c74d",
                      marginTop: "25px",
                      marginLeft: "10px",
                      "&:hover": {
                        color: "#a8c74d",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>

            <div
              className="input-gaon-box"
              style={{ width: "100%", margin: "4px" }}
            >
              <div className="mb-2">
                <TextField
                  className="mt-4"
                  id="outlined-basic"
                  size="small"
                  label="Village"
                  placeholder="Enter village name in english"
                  variant="outlined"
                  value={villageNameEng}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                      ) &&
                      e.target.value.trim()
                    ) {
                      setVillageNameEng(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setVillageNameEng(e.target.value);
                    }
                  }}
                  fullWidth
                  required
                  sx={{ width: 380 }}
                />
              </div>
            </div>

            <div
              className="input-gaon-box"
              style={{ width: "100%", margin: "4px" }}
            >
              <div className="mb-2">
                <TextField
                  className="mt-4"
                  id="outlined-basic"
                  size="small"
                  label="गाव"
                  placeholder="गावाचे नाव मराठीत प्रविष्ट करा..."
                  variant="outlined"
                  value={villageNameMr}
                  onChange={(e) => {
                    if (
                      e.target.value.match(/^[\u0900-\u097F() /-]+$/) &&
                      e.target.value.trim()
                    ) {
                      setVillageNameMr(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setVillageNameMr(e.target.value);
                    }
                  }}
                  fullWidth
                  required
                  sx={{ width: 380 }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: "30px",
              marginLeft: "10px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "30%",
                fontSize: "15px",
                fontWeight: "500",
                backgroundColor: "#A8C74D",
                padding: "10px 0px",
                marginTop: "30px",
                "&:hover": {
                  backgroundColor: "#A8C74D",
                  transition: "all 0.2s",
                },
              }}
              fullWidth
            >
              Submit
            </Button>
          </div>
        </Form>
        <ToastContainer autoClose={2000} />
      </Paper>
    </>
  );
};

export default SearchAndDropdownCopy;
