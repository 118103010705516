import React,{useEffect, useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import LineChart from "./Charts/LineChart";
import PieChart from "./Charts/PieChart";

const AdminHome = () => {

  /* --- District Pie Charts Variables -----*/
  const [pieChartLabel, setPieChartLabel] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);

  /* --- Grampanchayat Line Chart Variables ----*/
  const [LineChartlabel, setLineChartLabel] = useState([]);
  const [LineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    getDistrict();
    getGrampanchayat();
  }, []);

  const getDistrict = async () => {
    // setLoader(true);
    await fetch(process.env.REACT_APP_ALL_API + `/gaonConnectWhatsapp/getgaonconnectwhatsappdetailscountbydistrict`, {
      method: "GET"
    }) .then((res) => res.json())
      .then((response) => {
        console.log("Disttrict Data For Chart ==>", response);

        // ------ Setting Data for Surplus/Deficit Bar Chart ---------
            setPieChartLabel(response.districts);
            setPieChartData(response.districtCount);
      }).catch((err)=>{
         console.log("Error", err);
        })
      }

      const getGrampanchayat = async()=>{
        await fetch(process.env.REACT_APP_ALL_API + `/gaonConnectWhatsapp/getgaonconnectwhatsappdetailscountbygrampanchayat`, {
          method: "GET"
        }) .then((res) => res.json())
          .then((response) => {
            console.log("Taluka Data for Charts ==>", response);
    
            // ------ Setting Data for Surplus/Deficit Bar Chart ---------
                setLineChartLabel(response.grampanchayats);
                let GrampanchayatArray = [];
                GrampanchayatArray.push({
                    label: "Users",
                    data: response.grampanchayatCounts,
                    borderColor: "#118DFF",
                  });
                setLineChartData(GrampanchayatArray);
          }).catch((err)=>{
             console.log("Error", err);
            })
      } 
  return (
    <>
      <Container className="Charts">
        <Row className="d-flex justify-content-center">
        <h1 className="text-center">Welcome, Gaon Connect !</h1>
          <Col md={8}>
          <PieChart chartLabels={pieChartLabel} chartLabel="Users" 
            chartData={pieChartData} chartHeading="District Data"/>
          </Col>
        </Row>

        <Row className='d-flex justify-content-center mt-5'>
          <Col md={8}>
          <LineChart months={LineChartlabel} Array={LineChartData} chartHeading="Grampanchayat Data" />
          </Col>
        </Row> 
      </Container>
    </>
  );
};

export default AdminHome;
