import { Paper, Typography, Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";

const UserForm = () => {
  const navigate = useNavigate();
  const findloc = useLocation();
  const loc = findloc.state[0].grampanchayat;
  // console.log("grampanchayat id", loc?._id);
  // console.log("grampanchayat id", findloc.state[0].grampanchayat);

  // console.log("printing loc", loc.whatsapp_number);

  // toastify
  const notifyEng = () => {
    toast.success("Submitted Successfully", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const notifyMar = () =>
    toast.success("यशस्वीरित्या प्रविष्ट केले", {
      position: toast.POSITION.TOP_CENTER,
    });

  const notifyEngError = () =>
    toast.error("User already added", {
      position: toast.POSITION.TOP_CENTER,
    });

  const notifyMarError = () =>
    toast.error("वापरकर्ता आधीच जोडला आहे", {
      position: toast.POSITION.TOP_CENTER,
    });

  // userForm
  const [userForm, setUserForm] = useState({
    name: "",
    contact_no: "",
    district: loc?.district_id,
    taluka: loc?.taluka_id,
    grampanchayat: loc?._id,
    village: "",
    gender: "",
    dob: "",
  });

  // loader
  const [loaders, setLoaders] = useState(false);

  let name, value;
  const handleInputs = (e) => {
    console.log("villaagegfgfh", e);
    name = e.target.name;
    value = e.target.value;
    setUserForm({ ...userForm, [name]: value });
  };
  useEffect(() => {
    getLang();
  }, []);
  const [lang, setLang] = React.useState("marathi");

  const changeLang = () => {
    if (lang === "marathi") {
      localStorage.setItem("lang", "english");
      getLang();
    } else if (lang === "english") {
      localStorage.setItem("lang", "marathi");
      getLang();
    }
  };

  const getLang = () => {
    if (localStorage.getItem("lang") === null) {
      localStorage.setItem("lang", "marathi");
      setLang("marathi");
    } else {
      setLang(localStorage.getItem("lang"));
    }
  };

  const [nameerror, setNameError] = useState(false);
  const [contacterror, setContactError] = useState(false);
  const [villaerror, setVillaerror] = useState(false);
  const [gendererror, setGenderError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const validate = () => {
    let FormValid = true;
    if (userForm.name.length === 0) {
      FormValid = false;
      setNameError(true);
    }

    if (userForm.contact_no.length === 0) {
      FormValid = false;
      setContactError(true);
    } else if (userForm.contact_no.length < 10) {
      FormValid = false;
      setContactError(true);
    }

    // if (userForm.village.length === 0) {
    //   FormValid = false;
    //   setVillaerror(true);
    // }

    // if (userForm.gender.length === 0) {
    //   FormValid = false;
    //   setGenderError(true);
    // }

    // if (userForm.dob.length === 0) {
    //   FormValid = false;
    //   setDateError(true);
    // } else if (userForm.dob.length > 0) {
    //   setDateError(false);
    // }
    return FormValid;
  }

  useEffect(() => {
    getVillageById();
  }, [userForm.village]);

  const [getVillage, setGetVillage] = useState([]);

  const getVillageById = () => {
    const villageData = {
      grampanchayat_id: loc?._id,
    };

    axios
      .post(
        process.env.REACT_APP_ALL_API + "/village/getvillagebygrampanchayatid",
        villageData
      )
      .then((res) => {
        let result = res.data.data;
        // let en = []
        // let mar = []
        // result.map((item) => {
        //   en.push()
        //   mar.push(item.village_name_mr)
        // });
        if (result.length === 1) {
          setUserForm({ ...userForm, village: result[0]._id });
        }
        setGetVillage(result);
        // console.log("result---82", result);
        // console.log("get village by grampanchayat id****", res.data.data);
      })
      .catch((error) => console.log(error));
  };
  // console.log("getvillage", getVillage);
  // console.log("getvillage", getVillage);
  // Gaon Connect Whatsapp
  const handleOnsubmit = async (e) => {
    e.preventDefault();
    setLoaders(true);
    setNameError(false);
    setContactError(false);
    // setVillaerror(false);
    // setGenderError(false);
    // setDateError(false);
    // console.log("getVillage.length", getVillage);

    if (validate()) {
      console.log("inside validation");
      const {
        name,
        contact_no,
        district,
        taluka,
        grampanchayat,
        village,
        gender,
        dob,
      } = userForm;
      const dataa = {
        name: name,
        contact_no: contact_no,
        district: district,
        taluka: taluka,
        grampanchayat: grampanchayat,
        village: getVillage.length === 1 ? getVillage[0]._id : village,
        gender: gender,
        date_of_birth: dob,
      };
      await axios
        .post(
          process.env.REACT_APP_ALL_API +
            "/gaonconnectwhatsapp/insertgaonconnectwhatsappdetails",
          dataa
        )
        .then((data) => {
          console.log("submit", data);
          if (data.data.message === "Contact number already exists") {
            lang === "marathi" ? notifyMarError() : notifyEngError();
            setLoaders(false);
          } else if (data.data.status === 200) {
            {
              lang === "marathi" ? notifyMar() : notifyEng();
            }
            setTimeout(() => {
              setLoaders(false);
            }, 2000);
            if (lang === "marathi") {
              setTimeout(() => {
                setLoaders(false);

                // console.log("$$$$$", loc.whatsapp_number);
                // window.open(`https://wa.me/+918956581430?text=कृपया वरील नंबर गाव कनेक्ट नावाने सेव्ह करा आणि सरकारी योजना व गावातील अपडेट्स मिळ        ळवा..`,'_blank');
                window.location.href = `https://wa.me/+91${loc?.whatsapp_number}?text=${loc?.whatsapp_message_mr}`;

              }, 2000);
            } else {
              setTimeout(() => {
                setLoaders(false);
                // window.open(`https://wa.me/+918956581430?text=Please save the above number as Gaon Connect and get Government scheme and village updates regularly.`,'_blank');
                window.location.href = `https://wa.me/+91${loc?.whatsapp_number}?text=${loc?.whatsapp_message_en}`;
              }, 2000);
            }
          }
        })
        .catch((err) => console.log("ERROR", err));
    } else {
      setLoaders(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <Paper
        className="paper-wrapper"
        sm={{ width: "50%" }}
        sx={{
          width: "70%",
          margin: "120px auto",

          padding: "10px 30px",
        }}
      >
        <form onSubmit={handleOnsubmit}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // marginBottom: "40px 0px 0px 80px",
            }}
          >
            <label className="switch">
              <input
                className="switch-input"
                type="checkbox"
                onChange={changeLang}
              />
              <span
                className="switch-label"
                data-on={lang === "marathi" ? "मराठी" : "ENGLISH"}
                data-off={lang === "marathi" ? "मराठी" : "ENGLISH"}
              ></span>{" "}
              <span className="switch-handle"></span>
            </label>
          </div>

          <div
            className="first_name"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              className="fields"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {lang === "marathi" ? "तुमचे नाव " : "Your Name "}
              </Form.Label>
              <Form.Control
                name="name"
                placeholder={lang === "marathi" ? "तुमचे नाव " : "Your Name "}
                value={userForm.name}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    handleInputs(e);
                  } else if (e.target.value.length === 0) {
                    handleInputs(e);
                  }
                  userForm.name.length >= 0
                    ? setNameError(false)
                    : setNameError(true);
                }}
              />
              {nameerror ? (
                <Typography
                  variant="subtitle1"
                  sx={{ color: "red", marginBottom: "5px" }}
                >
                  {lang === "marathi"
                    ? "कृपया वैध नाव प्रविष्ट करा"
                    : "Please Enter valid name"}
                </Typography>
              ) : null}
            </div>

            <div className="fields">
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {lang === "marathi" ? "संपर्क क्रमांक" : "Contact No"}
              </Form.Label>
              <Form.Control
                name="contact_no"
                placeholder={
                  lang === "marathi" ? "संपर्क क्रमांक" : "Contact No"
                }
                value={userForm.contact_no}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.length <= 10
                  ) {
                    handleInputs(e);
                  } else if (e.target.value.length === 0) {
                    handleInputs(e);
                  }
                  userForm.contact_no.length >= 0
                    ? setContactError(false)
                    : setContactError(true);
                }}
              />
              {contacterror ? (
                <Typography
                  variant="subtitle1"
                  sx={{ color: "red", marginBottom: "5px" }}
                >
                  {lang === "marathi"
                    ? "कृपया 10 अंकी संपर्क क्रमांक टाका"
                    : "Please enter 10 digit mobile number"}
                </Typography>
              ) : null}
            </div>
          </div>

          <div className="first_name">
            <div className="fields">
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {lang === "marathi" ? "जिल्हा" : "District"}
              </Form.Label>
              <Form.Control
                aria-label="Default select example"
                placeholder={
                  lang === "marathi" ? "जिल्हा निवडा" : "Select district"
                }
                value={
                  lang === "marathi"
                    ? loc?.district_name_mr
                    : loc?.district_name_en
                }
                name="district"
                disabled
              />
            </div>

            <div className="fields">
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {lang === "marathi" ? "तालुका" : "Taluka"}
              </Form.Label>
              <Form.Control
                aria-label="Default select example"
                // value={userForm.taluka}
                value={
                  lang === "marathi" ? loc?.taluka_name_mr : loc?.taluka_name_en
                }
                placeholder={
                  lang === "marathi" ? "तालुका निवडा" : "Select taluka"
                }
                name="taluka"
                disabled
              />
            </div>
          </div>

          <div className="first_name">
            <div className="fields">
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {lang === "marathi" ? "ग्रामपंचायत" : "Grampanchayat"}
              </Form.Label>
              <Form.Control
                aria-label="Default select example"
                placeholder={
                  lang === "marathi"
                    ? "ग्रामपंचायत निवडा"
                    : "Select grampanchayat"
                }
                value={
                  lang === "marathi"
                    ? loc?.grampanchayat_name_mr
                    : loc?.grampanchayat_name_en
                }
                name="grampanchayat"
                disabled
              />
            </div>

            <div className="fields">
              <Form.Group>
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  {lang === "marathi" ? "गाव" : "Village"}
                </Form.Label>
                <Form.Select
                  name="village"
                  value={userForm.village}
                  fullWidth
                  onChange={(e) => {
                    handleInputs(e);
                    // userForm.village.length < 0
                    //   ? setVillaerror(true)
                    //   : setVillaerror(false);
                  }}
                  disabled={getVillage.length === 1 ? true : false}
                >
                  {getVillage.length > 1 ? (
                    <option value="" selected disabled>
                      {lang === "marathi" ? "गाव निवडा" : "Select Village"}
                    </option>
                  ) : null}

                  {getVillage.length > 1 ? (
                    getVillage !== undefined &&
                    getVillage.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {lang === "marathi"
                            ? item?.village_name_mr
                            : item?.village_name_en}
                        </option>
                      );
                    })
                  ) : (
                    <option>
                      {lang === "marathi"
                        ? getVillage[0]?.village_name_mr
                        : getVillage[0]?.village_name_en}
                    </option>
                  )}
                </Form.Select>
                {/* {villaerror ? (
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "red", marginBottom: "5px" }}
                  >
                    {lang === "marathi"
                      ? "कृपया गाव निवडा"
                      : "Please Select Village"}
                  </Typography>
                ) : null} */}

                {/*
                {lang === "marathi" ? (
                  <Form.Select name="Village" value={userForm.village}>
                    {getVillageMr !== undefined && getVillageMr.length > 0
                      ? getVillageMr ?.map((row, index) => {
                          return (
                            <option key={index} value={row}>
                              {row.village_name_mr}
                            </option>
                          );
                        })
                      : ""
                      }
                  </Form.Select>
                ) : (
                  <Form.Select name="Village" value={userForm.village}>
                    {getVillageEn !== undefined && getVillageEn.length > 0
                      ? getVillageEn?.map((row, index) => {
                          return (
                            <option key={index} value={row}>
                              {row.village_name_en}
                            </option>
                          );
                        })
                      : ""
                      }
                  </Form.Select>
                )} */}
              </Form.Group>
              {/*

                <Form.Select
                  // aria-label="Default select example"
                  // placeholder={
                  //   lang === "marathi" ? "गाव निवडा" : "Select village"
                  // }
                  value={lang === "marathi" ? getVillageMr : getVillageEn}
                  name="village"
                >
                  {getVillageEn !== undefined &&
                  getVillageMr !== undefined &&
                  getVillageEn.length > 0 &&
                  getVillageMr.length > 0
                    ? lang === "marathi"
                      ? getVillageMr !== undefined &&
                        getVillageMr.map((items) => {
                          console.log("441-->", items?.item);
                          return <option>{items?.item}</option>;
                        })
                      : getVillageEn !== undefined &&
                        getVillageEn.map((vals) => {
                          console.log("446-->", vals?.item);
                          return <option>{vals?.item}</option>;
                        })
                    : ""}
                </Form.Select> */}

              {/* {getVillage?.length <= 1 ? (
                <Form.Control
                  aria-label="Default select example"
                  placeholder={
                    lang === "marathi" ? "गाव निवडा" : "Select village"
                  }
                  value={
                    lang === "marathi"
                      ? getVillage[0]?.village_name_mr
                      : getVillage[0]?.village_name_en
                  }
                  onChange={(e) => {
                    {
                      handleInputs(e);
                    }
                    userForm.village.length > 0
                      ? setVillaerror(false)
                      : setVillaerror(true);
                  }}
                  name="village"
                  disabled
                />
              ) : (
                <>
                  <Form.Select
                    aria-label="Default select example"
                    value={userForm.village}
                    name="village"
                    onChange={(e) => {
                      {
                        handleInputs(e);
                      }
                      userForm.village.length >= 0
                        ? setVillaerror(false)
                        : setVillaerror(true);
                    }}
                  >
                    <option value="" selected disabled>
                      {lang === "marathi" ? "गाव निवडा" : "Select village"}
                    </option>
                    {console.log("Village-->", getVillage)}
                    {getVillage !== undefined && getVillage !== null
                      ? getVillage?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.village_name_en}
                              {lang === "marathi"
                              ? item.village_name_mr
                              : item.village_name_en}
                            </option>
                          );
                        })
                      : ""}
                  </Form.Select>
                  {villaerror ? (
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "red", marginBottom: "5px" }}
                    >
                      {lang === "marathi"
                        ? "कृपया गाव निवडा"
                        : "Please Select Village"}
                    </Typography>
                  ) : null}
                </>
              )} */}
            </div>
          </div>

          <div className="first_name">
            <div className="fields">
              <Form.Label>
                <span style={{ color: "red" }}>*</span>{" "}
                {lang === "marathi" ? "लिंग " : "Gender "}
              </Form.Label>
              <Form.Select
                name="gender"
                value={userForm.gender}
                onChange={(e) => {
                  handleInputs(e);
                  // userForm.gender.length < 0
                  //   ? setGenderError(true)
                  //   : setGenderError(false);
                }}
              >
                <option value="" selected disabled>
                  {lang === "marathi" ? "लिंग निवडा" : "Select gender"}
                </option>
                <option value="Male">
                  {lang === "marathi" ? "पुरुष" : "Male"}
                </option>
                <option value="Female">
                  {lang === "marathi" ? "स्त्री" : "Female"}
                </option>
                <option value="Other">
                  {lang === "marathi" ? "इतर" : "Other"}
                </option>
              </Form.Select>
              {/* {gendererror ? (
                <Typography
                  variant="subtitle1"
                  sx={{ color: "red", marginBottom: "5px" }}
                >
                  {lang !== "marathi"
                    ? "Please select gender"
                    : "कृपया लिंग निवडा"}
                </Typography>
              ) : null} */}
            </div>

            <div className="fields">
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {lang === "marathi" ? "जन्म तारीख " : "Date of Birth "}
              </Form.Label>
              <Form.Control
                type="date"
                max="2023-03-01"
                id="startDateInput"
                onKeyDown={(event) => event.preventDefault()}
                name="dob"
                onChange={(e) => {
                  setUserForm({
                    ...userForm,
                    dob: moment(e.target.value).format("YYYY-MM-DD"),
                  });
                  // userForm.dob.length < 0
                  //   ? setDateError(true)
                  //   : setDateError(false);
                }}
              />
              {/* {dateError ? (
                <Typography
                  variant="subtitle1"
                  sx={{ color: "red", marginBottom: "5px" }}
                >
                  {lang !== "marathi"
                    ? "Please select birth date"
                    : "कृपया जन्मतारीख निवडा"}
                </Typography>
              ) : null} */}
            </div>
          </div>

          <div
            className="submit-button"
            // style={{
            //   display: "flex",
            //   justifyContent: "center",
            //   width: "100%",
            //   marginBottom: "5px",
            // }}
          >
            {loaders ? (
              <div class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
            ) : (
              <Button
                type="submit"
                onClick={handleOnsubmit}
                variant="contained"
                sx={{
                  width: "30%",
                  fontSize: "15px",
                  fontWeight: "500",
                  backgroundColor: "#A8C74D",
                  padding: "10px 0px",
                  marginTop: "20px",
                  marginBottom:"20px",

                  "&:hover": {
                    backgroundColor: "#A8C74D",
                    transition: "all 0.2s",
                  },
                }}
                sm={{
                  // width: "60%",
                }}
                fullWidth
              >
                {lang === "marathi" ? "सबमिट करा" : "Submit"}
              </Button>
            )}
          </div>
        </form>
      </Paper>
    </>
  );
 };

export default UserForm;