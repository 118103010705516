import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import { Box } from "@mui/system";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import { toast, ToastContainer } from "react-toastify";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Textarea from "@mui/joy/Textarea";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const GrampanchayatDetails = () => {
  // pagination and loader
  const [loaders, setLoaders] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const textStyle = { marginBottom: "20px" };

  const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const grampanchayatAlreadyAdded = () => {
    toast.error("Grampanchayat already added!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const grampanchayatAdded = () => {
    toast.success("Grampanchayat added successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const grampanchayatupdated = () => {
    toast.success("Grampanchayat updated successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const grampanchayatdeleted = () => {
    toast.success("Grampanchayat deleted successfully!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const addData = () => {
    toast.error("Grampanchayat data not found, Please add Grampanchayat!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const grampanchayatNotFound = () => {
    toast.error("Grampanchayat data not found, Please add Grampanchayat!🙂", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const notify = () => {
    toast.error("Please enter 10 digit whatsapp number", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // const Updatenotify = () => {
  //   toast.error("Please enter 10 digit whatsapp number", {
  //     position: toast.POSITION.TOP_CENTER,
  //   });
  // };

  // model data

  let name, value;
  const handleOnChange = (e) => {
    console.log("id-------", e);
    name = e.target.name;
    value = e.target.value;
    setData({ ...data, [name]: value });
    console.log("value----", value);
  };

  const [grampanchayatData, setGrampanchayatData] = useState({
    district_name_en: "",
    taluka_name_en: "",
    grampanchayat_name_en: "",
    grampanchayat_name_mr: "",
    whatsapp_number: "",
    whatsapp_message_en: "",
    whatsapp_message_mr: "",
  });

  const handleGrampanchayatOnChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setGrampanchayatData({ ...grampanchayatData, [name]: value });
  };

  // open add modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [editGrampanchayat, setEditGrampanchayat] = useState({
    _id: "",
    grampanchayat_name_en: "",
    grampanchayat_name_mr: "",
    whatsapp_number: "",
  });

  // open Edit model
  const [openEditModal, setOpenEditModel] = React.useState(false);
  const handleOpenEditModel = (vals) => {
    setOpenEditModel(true);
    setEditGrampanchayat({
      _id: vals?._id,
      grampanchayat_name_en: vals?.grampanchayat_name_en,
      grampanchayat_name_mr: vals?.grampanchayat_name_mr,
      whatsapp_number: vals?.whatsapp_number,
      whatsapp_message_en: vals?.whatsapp_message_en,
      whatsapp_message_mr: vals?.whatsapp_message_mr,
    });
  };
  const handleCloseEditModel = () => setOpenEditModel(false);

  const handleModelEditOnchange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setEditGrampanchayat({ ...editGrampanchayat, [name]: value });
  };

  //open view model
  const [viewGrampanchayat, setViewGrampanchayat] = useState({
    district_name_en: "",
    taluka_name_en: "",
    grampanchayat_name_en: "",
    grampanchayat_name_mr: "",
    whatsapp_number: "",
    url: "",
  });
  const [openViewModal, setOpenViewModel] = React.useState(false);
  const handleOpenViewModel = (vals) => {
    setOpenViewModel(true);
    setViewGrampanchayat({
      district_name_en: vals?.district_name_en,
      taluka_name_en: vals?.taluka_name_en,
      grampanchayat_name_en: vals?.grampanchayat_name_en,
      grampanchayat_name_mr: vals?.grampanchayat_name_mr,
      whatsapp_number: vals?.whatsapp_number,
      url: vals?.url,
    });
  };
  const handleCloseViewModel = () => {
    setOpenViewModel(false);
    setCopyLink(false);
  };

  // open delete model
  const [deletegrampanchayat, setDeleteGrampanchayat] = useState({
    _id: "",
  });
  const [openDeleteModal, setOpenDeleteModel] = React.useState(false);
  const handleOpenDeleteModel = (vals) => {
    setOpenDeleteModel(true);
    setDeleteGrampanchayat({
      _id: vals?._id,
    });
  };
  const handleCloseDeleteModel = () => setOpenDeleteModel(false);

  const [districtData, setDistrictData] = useState([]);
  const [talukaData, setTalukaData] = useState([]);
  const [talukaDataArray, setTalukaDataArray] = useState([]);
  const [grampanchayatDataById, setGrampanchayatDataById] = useState([]);
  const [grampanchayatDataByIdreverse, setGrampanchayatDataByIdReverse] =
    useState([]);

  const [data, setData] = useState({
    district: "",
    taluka: "",
  });
  const [talukaModelData, setTalukaModelData] = useState([]);

  //add district

  const validate = () => {
    let FormValid = true;
    if (
      grampanchayatData.whatsapp_number.match(
        /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
      ) ||
      grampanchayatData.whatsapp_number.length !== 10
    ) {
      FormValid = false;
      notify();
    }
    return FormValid;
  };

  const addGrampanchayat = async (e) => {
    e.preventDefault(e);
    if (validate()) {
      const {
        district_name_en,
        taluka_name_en,
        grampanchayat_name_en,
        grampanchayat_name_mr,
        whatsapp_number,
        whatsapp_message_en,
        whatsapp_message_mr,
      } = grampanchayatData;
      const gramData = {
        district_name_en: district_name_en,
        taluka_name_en: taluka_name_en,
        grampanchayat_name_en:
          grampanchayat_name_en.charAt(0).toUpperCase() +
          grampanchayat_name_en.slice(1).trim(),
        grampanchayat_name_mr: grampanchayat_name_mr.trim(),
        whatsapp_number: whatsapp_number,
        whatsapp_message_en: whatsapp_message_en,
        whatsapp_message_mr: whatsapp_message_mr,
      };
      await axios
        .post(
          process.env.REACT_APP_ALL_API + "/grampanchayat/addgrampanchayat",
          gramData
        )
        .then((res) => {
          setGrampanchayatData({
            district_name_en: "",
            taluka_name_en: "",
            grampanchayat_name_en: "",
            grampanchayat_name_mr: "",
            whatsapp_number: "",
            whatsapp_message_en: "",
            whatsapp_message_mr: "",
          });
          getGrampanchayatData();
          if (res?.data?.message === "Grampanchayat already added") {
            grampanchayatAlreadyAdded();
            setOpen(false);
          } else if (
            res?.data?.message === "Grampanchayat Added Successfully"
          ) {
            grampanchayatAdded();
            setOpen(false);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  //district API
  useEffect(() => {
    getDistrictData();
    getTalukaData();
  }, []);

  const getDistrictData = () => {
    axios
      .get(process.env.REACT_APP_ALL_API + "/district/getalldistricts")
      .then((res) => {
        setDistrictData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setData({ ...data, taluka: "" });
    getTalukaDataByDistrict();
  }, [data?.district, grampanchayatData?.district_name_en]);

  // useEffect(() => {
  //   getTalukaDataByDistrict();
  // }, [grampanchayatData.district_name_en]);

  const getTalukaDataByDistrict = () => {
    const result = talukaData?.filter(
      (event) => event.district_id === data?.district
    );

    const modelresult = talukaData?.filter(
      (event) => event.district_name_en === grampanchayatData?.district_name_en
    );

    setTalukaDataArray(result);
    setTalukaModelData(modelresult);
  };

  console.log("result data 328-------", talukaDataArray);
  console.log("district Data 329----", districtData);

  const getTalukaData = () => {
    axios
      .get(process.env.REACT_APP_ALL_API + "/taluka/getalltalukas")
      .then((res) => {
        setTalukaData(res?.data?.data);
      })
      .catch((err) => {
        console.log("Error While Get Taluka", err);
      });
  };

  useEffect(() => {
    getGrampanchayatData();
  }, []);

  useEffect(() => {
    getGrampanchayatData();
  }, [data.district, data.taluka]);

  const getGrampanchayatData = async () => {
    const payload = {
      district_id: data.district,
      taluka_id: data.taluka,
    };
    await axios
      .post(
        process.env.REACT_APP_ALL_API +
          "/grampanchayat/getgrampanchayatbytalukaid",
        payload
      )
      .then((res) => {
        console.log("response-----------361", res);
        if (res.data.data === null) {
          setGrampanchayatDataById(res?.data?.data);
        } else {
          setGrampanchayatDataById(res?.data?.data.reverse());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateValidate = () => {
    let FormValid = true;
    if (
      editGrampanchayat.whatsapp_number.match(
        /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
      ) ||
      editGrampanchayat.whatsapp_number.length !== 10
    ) {
      FormValid = false;
      notify();
    }
    return FormValid;
  };

  const updateGrampanchayatData = async (e) => {
    e.preventDefault();
    if (updateValidate()) {
      const {
        _id,
        grampanchayat_name_en,
        grampanchayat_name_mr,
        whatsapp_number,
        whatsapp_message_en,
        whatsapp_message_mr,
      } = editGrampanchayat;
      const datas = {
        _id: _id,
        grampanchayat_name_en:
          grampanchayat_name_en.charAt(0).toUpperCase() +
          grampanchayat_name_en.slice(1).trim(),
        grampanchayat_name_mr: grampanchayat_name_mr.trim(),
        whatsapp_number: whatsapp_number,
        whatsapp_message_en: whatsapp_message_en,
        whatsapp_message_mr: whatsapp_message_mr,
      };
      await axios
        .post(
          process.env.REACT_APP_ALL_API + "/grampanchayat/updategrampanchayat",
          datas
        )
        .then((res) => {
          getGrampanchayatData();
          if (res?.data?.message === "Grampanchayat updated successfully") {
            grampanchayatupdated();
            setOpenEditModel(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteGrampanchayatData = async () => {
    const { _id } = deletegrampanchayat;
    const datas = {
      _id: _id,
    };
    await axios
      .post(
        process.env.REACT_APP_ALL_API + "/grampanchayat/deletegrampanchayat",
        datas
      )
      .then((res) => {
        getGrampanchayatData();
        if (res.data.message === "Grampanchayat deleted successfully") {
          grampanchayatdeleted();
          setOpenDeleteModel(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [copyLink, setCopyLink] = useState(false);

  const copyToClipboard = (url) => {
    setCopyLink(true);
    setTimeout(() => {
      setOpenViewModel(false);
    }, 1000);

    navigator.clipboard.writeText(url);
    toast.success("👍 Copied to Clipboard! ", {
      duration: 2000,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <>
      <ToastContainer autoClose={2000} />
      <Modal
        style={{}}
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Add Grampanchayat
            </Typography>
            <form onSubmit={(e) => addGrampanchayat(e)}>
              <FormControl
                sx={{ minWidth: 440, marginBottom: "20px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">District</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  required
                  name="district_name_en"
                  value={grampanchayatData?.district_name_en}
                  label="District"
                  onChange={(e) => handleGrampanchayatOnChange(e)}
                >
                  <MenuItem selected disabled>
                    <em>Select District</em>
                  </MenuItem>
                  {districtData !== undefined &&
                  districtData !== null &&
                  districtData.length > 0
                    ? districtData.map((vals, index) => {
                        return (
                          <MenuItem value={vals?.district_name_en}>
                            {vals?.district_name_en}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>

              <FormControl
                sx={{ minWidth: 440, marginBottom: "20px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">Taluka</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  required
                  name="taluka_name_en"
                  value={grampanchayatData?.taluka_name_en}
                  label="Taluka"
                  onChange={(e) => handleGrampanchayatOnChange(e)}
                >
                  <MenuItem selected disabled>
                    <em>Select Taluka</em>
                  </MenuItem>
                  {talukaModelData !== undefined &&
                  talukaModelData !== null &&
                  talukaModelData.length > 0
                    ? talukaModelData.map((vals, index) => {
                        return (
                          <MenuItem value={vals?.taluka_name_en}>
                            {vals?.taluka_name_en}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>
              
              <TextField
                label="Grampanchayat"
                variant="outlined"
                placeholder="Enter Grampanchayat Name"
                fullWidth
                required
                size="small"
                name="grampanchayat_name_en"
                style={textStyle}
                value={grampanchayatData?.grampanchayat_name_en}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    handleGrampanchayatOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleGrampanchayatOnChange(e);
                  }
                }}
              />
              <TextField
                label="ग्रामपंचायत"
                variant="outlined"
                placeholder="ग्रामपंचायत प्रविष्ट करा"
                fullWidth
                required
                size="small"
                name="grampanchayat_name_mr"
                style={textStyle}
                value={grampanchayatData?.grampanchayat_name_mr}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                    e.target.value.trim()
                  ) {
                    handleGrampanchayatOnChange(e);
                  } else if (e.target.value.length === 0) {
                    handleGrampanchayatOnChange(e);
                  }
                }}
              />
              <TextField
                label="Whatsapp Number"
                variant="outlined"
                placeholder="Enter Whatsaap number"
                fullWidth
                required
                size="small"
                name="whatsapp_number"
                style={textStyle}
                value={grampanchayatData.whatsapp_number}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.length <= 10
                  ) {
                    handleGrampanchayatOnChange(e);
                  }
                }}
              />
              <Textarea
                label="message"
                variant="outlined"
                placeholder="Enter Whatsaap message here..."
                fullWidth
                required
                minRows={2}
                // size="sm"
                name="whatsapp_message_en"
                style={textStyle}
                value={grampanchayatData?.whatsapp_message_en}
                onChange={(e) => {
                  handleGrampanchayatOnChange(e);
                }}
              />
              <Textarea
                label="संदेश"
                variant="outlined"
                placeholder="संदेश प्रविष्ट करा..."
                fullWidth
                required
                minRows={2}
                // size="sm"
                name="whatsapp_message_mr"
                style={textStyle}
                value={grampanchayatData?.whatsapp_message_mr}
                onChange={(e) => {
                  handleGrampanchayatOnChange(e);
                }}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#a8c74d",
                  padding: "10px 0px",
                  "&:hover": {
                    color: "#a8c74d",
                    backgroundColor: "white",
                    transition: "all 0.2s",
                  },
                }}
              >
                Add
              </Button>
            </form>
          </Paper>
        </Box>
      </Modal>

      <Modal
        style={{}}
        keepMounted
        open={openEditModal}
        onClose={handleCloseEditModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "25px",
              }}
            >
              Update Grampanchayat
            </Typography>
            <form onSubmit={(e) => updateGrampanchayatData(e)}>
              <TextField
                label="Grampanchayat"
                variant="outlined"
                fullWidth
                required
                size="small"
                name="grampanchayat_name_en"
                style={textStyle}
                value={editGrampanchayat?.grampanchayat_name_en}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:{}\[\]*.|//\/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    handleModelEditOnchange(e);
                  } else if (e.target.value.length === 0) {
                    handleModelEditOnchange(e);
                  }
                }}
              />
              <TextField
                label="ग्रामपंचायत"
                variant="outlined"
                fullWidth
                required
                size="small"
                name="grampanchayat_name_mr"
                style={textStyle}
                value={editGrampanchayat?.grampanchayat_name_mr}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[\u0900-\u097F() \-]+$/) &&
                    e.target.value.trim()
                  ) {
                    handleModelEditOnchange(e);
                  } else if (e.target.value.length === 0) {
                    handleModelEditOnchange(e);
                  }
                }}
              />
              <TextField
                label="Whatsapp Number"
                variant="outlined"
                fullWidth
                required
                size="small"
                name="whatsapp_number"
                style={textStyle}
                value={editGrampanchayat?.whatsapp_number}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[A-Za-z+@#$&%!~=^_:{}\[\]*.|/\s/g/\/?<>,;`'""/\\]/
                    ) &&
                    e.target.value.length <= 10
                  ) {
                    handleModelEditOnchange(e);
                  } else if (e.target.value.length === 0) {
                    handleModelEditOnchange(e);
                  }
                }}
              />
              <Textarea
                label="message"
                placeholder="Enter Whatsaap message here..."
                variant="outlined"
                fullWidth
                required
                minRows={2}
                name="whatsapp_message_en"
                style={textStyle}
                value={editGrampanchayat?.whatsapp_message_en}
                onChange={(e) => {
                  handleModelEditOnchange(e);
                }}
              />
              <Textarea
                label="संदेश"
                variant="outlined"
                placeholder="कृपया संदेश प्रविष्ट करा..."
                fullWidth
                required
                minRows={2}
                name="whatsapp_message_mr"
                style={textStyle}
                value={editGrampanchayat?.whatsapp_message_mr}
                onChange={(e) => {
                  handleModelEditOnchange(e);
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#a8c74d",
                  padding: "10px 0px",
                  "&:hover": {
                    color: "#a8c74d",
                    backgroundColor: "white",
                    transition: "all 0.2s",
                  },
                }}
              >
                Update
              </Button>
            </form>
          </Paper>
        </Box>
      </Modal>

      <Modal
        style={{}}
        keepMounted
        open={openViewModal}
        onClose={handleCloseViewModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper style={{ padding: "30px" }}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "25px",
              }}
            >
              View Grampanchayat Data
            </Typography>
            <TextField
              label="District"
              variant="outlined"
              fullWidth
              required
              size="small"
              name="grampanchayat_name_en"
              style={textStyle}
              value={viewGrampanchayat?.district_name_en}
            />
            <TextField
              label="District"
              variant="outlined"
              fullWidth
              required
              size="small"
              name="taluka_name_en"
              style={textStyle}
              value={viewGrampanchayat?.taluka_name_en}
            />
            <TextField
              label="Grampanchayat"
              variant="outlined"
              // placeholder="Enter Grampanchayat Name here"
              fullWidth
              required
              size="small"
              name="grampanchayat_name_en"
              style={textStyle}
              value={viewGrampanchayat?.grampanchayat_name_en}
            />
            <TextField
              label="ग्रामपंचायत"
              variant="outlined"
              // placeholder="कृपया ग्रामपंचायत प्रविष्ट करा"
              fullWidth
              required
              size="small"
              name="grampanchayat_name_mr"
              style={textStyle}
              value={viewGrampanchayat?.grampanchayat_name_mr}
            />
            <TextField
              label="Whatsapp Number"
              variant="outlined"
              // placeholder="Enter Whatsaap number"
              fullWidth
              required
              size="small"
              name="whatsapp_number"
              style={textStyle}
              value={viewGrampanchayat?.whatsapp_number}
            />
            <div
              className="copyLink"
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <FormControl
                sx={{ m: 1, width: "100%" }}
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  URL
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  value={viewGrampanchayat?.url}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {copyLink ? (
                          <DoneAllIcon />
                        ) : (
                          <ContentCopyIcon
                            onClick={() => {
                              copyToClipboard(viewGrampanchayat?.url);
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>

            {/* </form> */}
          </Paper>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModal}
        onClose={handleCloseDeleteModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={boxstyle}>
          <Paper
            style={{
              padding: "25px",
            }}
          >
            <Typography
              style={{ textAlign: "center", margin: "10px" }}
              variant="h6"
            >
              Are you sure You want to delete?
            </Typography>
            <Typography
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                size="small"
                style={{ backgroundColor: "green", color: "white" }}
                onClick={handleCloseDeleteModel}
              >
                No
              </Button>
              <Button
                variant="outlined"
                style={{ backgroundColor: "red", color: "white" }}
                onClick={deleteGrampanchayatData}
              >
                Yes
              </Button>
            </Typography>
          </Paper>
        </Box>
      </Modal>

      <Paper
        elevation={1}
        sx={{
          width: "100%",
          marginBottom: "10px",
          height: "60px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
              <InputLabel id="demo-select-small">District</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                name="district"
                value={data?.district}
                label="District"
                onChange={(e) => handleOnChange(e)}
              >
                <MenuItem selected disabled>
                  <em>Select District</em>
                </MenuItem>
                {districtData !== undefined &&
                districtData !== null &&
                districtData.length > 0
                  ? districtData.map((vals, index) => {
                      return (
                        <MenuItem value={vals?._id}>
                          {vals?.district_name_en}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
              <InputLabel id="demo-select-small">Taluka</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                name="taluka"
                value={data?.taluka}
                label="Taluka"
                onChange={(e) => handleOnChange(e)}
              >
                <MenuItem selected disabled>
                  <em>Select Taluka</em>
                </MenuItem>
                {talukaDataArray !== undefined &&
                talukaDataArray !== null &&
                talukaDataArray.length > 0
                  ? talukaDataArray.map((vals, index) => {
                      return (
                        <MenuItem value={vals?._id}>
                          {vals?.taluka_name_en}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>
          </div>
          <div style={{ padding: "10px" }}>
            <Button
              sx={{
                color: "white",
                backgroundColor: "#a8c74d",
                "&:hover": {
                  backgroundColor: "#c9ec60",
                  color: "white",
                  transition: "all 0.2s",
                },
              }}
              onClick={handleOpen}
            >
              Add Grampanchayat
              <AddBoxOutlined />
            </Button>
          </div>
        </div>
      </Paper>

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          margin: "10px auto",
          padding: "5px 0px",
        }}
      >
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              hover
              className="tablehead"
              sx={{
                backgroundColor: "#a8c74d",
              }}
            >
              <TableRow>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Sr.No
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  District
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Taluka
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Grampanchayat Name English
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Grampanchayat Name Marathi
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Whatsapp number
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Edit
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  View
                </TableCell>
                <TableCell sx={{ fontSize: "17px", fontWeight: 500 }}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loaders ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    class="spinner-border"
                    role="status"
                    style={{ display: "flex", justifyContent: "center" }}
                  ></div>

                  <span class="sr-only"></span>
                </div>
              ) : grampanchayatDataById !== undefined &&
                grampanchayatDataById !== null &&
                grampanchayatDataById.length > 0 ? (
                grampanchayatDataById
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((vals, index) => {
                    return (
                      <TableRow hover>
                        {page !== 0 ? (
                          <TableCell>
                            {index + 1 + rowsPerPage * page}
                          </TableCell>
                        ) : (
                          <TableCell>{index + 1}</TableCell>
                        )}
                        <TableCell>{vals?.district_name_en}</TableCell>
                        <TableCell>{vals?.taluka_name_en}</TableCell>
                        <TableCell>{vals?.grampanchayat_name_en}</TableCell>
                        <TableCell>{vals?.grampanchayat_name_mr}</TableCell>
                        <TableCell>{vals?.whatsapp_number}</TableCell>
                        <TableCell>
                          <EditOutlinedIcon
                            onClick={() => handleOpenEditModel(vals)}
                            sx={{
                              color: "white",
                              backgroundColor: "#a8c74d",
                              borderRadius: "50%",
                              padding: "2px",
                              "&:hover": {
                                backgroundColor: "#c9ec60",
                                color: "white",
                                transition: "all 0.2s",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <ViewListOutlinedIcon
                            onClick={() => handleOpenViewModel(vals)}
                            sx={{
                              color: "white",
                              backgroundColor: "#a8c74d",
                              borderRadius: "50%",
                              padding: "2px",
                              "&:hover": {
                                backgroundColor: "#c9ec60",
                                color: "white",
                                transition: "all 0.2s",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <DeleteOutlined
                            onClick={() => handleOpenDeleteModel(vals)}
                            sx={{
                              color: "white",
                              backgroundColor: "#a8c74d",
                              borderRadius: "50%",
                              padding: "2px",

                              "&:hover": {
                                backgroundColor: "#c9ec60",
                                color: "white",
                                transition: "all 0.2s",
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                "Data not found"
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {grampanchayatDataById !== null &&
        grampanchayatDataById !== undefined &&
        grampanchayatDataById.length > 0 ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 100]}
            count={
              grampanchayatDataById === null &&
              grampanchayatDataById === undefined
                ? 0
                : grampanchayatDataById
                ? grampanchayatDataById.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          ""
        )}
      </Paper>
    </>
  );
};

export default GrampanchayatDetails;
