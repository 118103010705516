import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableRow,

} from "@mui/material";

const LoadingSckeloton = () => {
  return (
    <>
      <SkeletonTheme highlightColor="#ecf8fd">
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableBody sx={{ width: "100" }}>
              <Skeleton style={{ height: "50px", width: "100%" }} />
              <Skeleton style={{ height: "50px" }} />
              <Skeleton style={{ height: "50px" }} />
              <Skeleton style={{ height: "50px" }} />
              <Skeleton style={{ height: "50px" }} />
            </TableBody>
          </Table>
        </TableContainer>
      </SkeletonTheme>
    </>
    // <Paper sx={{ margin: "20px auto", padding: "10px" }}>
    //   <SkeletonTheme highlightColor="#ecf8fd">
    //     <TableContainer>
    //       <Table>
    //         <Skeleton style={{ height: "50px" }} />
    //         <Skeleton style={{ height: "50px" }} />
    //         <Skeleton style={{ height: "50px" }} />
    //         <Skeleton style={{ height: "50px" }} />
    //         <Skeleton style={{ height: "50px" }} />
    //       </Table>
    //     </TableContainer>
    //   </SkeletonTheme>
    // </Paper>
  );
};

export default LoadingSckeloton;
