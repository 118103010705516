import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS } from 'chart.js/auto';
import { Card, Spinner, Modal, Button } from "react-bootstrap";
import CommonModal from './CommonModal';

const PieChart = ({ chartLabels, chartLabel, chartData, chartHeading }) => {
  const [loader, setLoader] = useState(false);

//   const ColorArray = [];
//   const BorderArray=[];

  useEffect(() => {
    if (chartData !== undefined && chartData.length > 0) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [chartData]);

  
  console.log("chartData At overll", chartData.length);

    //   for (var i = 0; i < chartData.length; i++) {
    //     if (chartData[i] > 0) {
    //       ColorArray.push("rgb(6, 169, 77, 0.4)");
    //       BorderArray.push("#059142");
    //     }
    //     if (chartData[i] < 0) {
    //       ColorArray.push("rgb(255, 0, 0, 0.3)");
    //       BorderArray.push("#FF0000");
    //     }
    //   }


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

   /* ----- Charts Props for sending ClickEvent Data to Common Modal ------ */
   const [label, setLabel ] = useState();
   const [dataLabel, setDataLabel] = useState();
   const [value, setValue] = useState();

  return (
    <>
      <Card style={{ height: "400px", width:"100%", padding: "20px 0px 40px 0px" }}>
        <h5 className="text-center">{chartHeading}</h5>
        {loader ? (
          <center>
            <Spinner />
          </center>
        ) : (
          <Pie
            data={{
              labels: chartLabels,
              fill: true,
              datasets: [
                {
                  label: chartLabel,
                  fill: true,
                  data: chartData,
                //   backgroundColor: ColorArray,
                //   borderColor:BorderArray,
                  borderWidth:2,
                  hoverOffset: 10
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              onClick: (e, element) => {
                console.log("Whole E", e);
                console.log("Element", element);

                let arr = [];
                e.chart.config._config.data.datasets.map((row) => {
                  arr.push(row.data[ element[0].index]);
                });

                setLabel(e.chart.data.labels[element[0].index]);
                setDataLabel(e.chart.config._config.data.datasets[element[0].datasetIndex].label);
                setValue(arr[element[0].datasetIndex]);
                setShow(true)
              },
            }}
          />
        )}
      </Card>

      <CommonModal open={show} close={ () => setShow(false)} data={{label, dataLabel, value}}/>
    </>
  );
};

export default PieChart;
