import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import gaonconnectLogo from "../../asset/appstore1024 x 1024-07.jpg";
import * as Icon from "react-bootstrap-icons";
import { Button, Spinner, Accordion } from "react-bootstrap";

const Menubar = () => {
  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute("data-visible");
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = "hidden";
    } else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = "auto";
    }
  }

  const naviagte = useNavigate();
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

 /* ---- States for handling parent Accordion Menu ----------*/
  const [activeKey, setActivekey] = useState((sessionStorage.getItem("activeKey")));
  const [navActiveKey, setNavActiveKey] = useState(sessionStorage.getItem("NavActiveKey"));

    useEffect(()=>{
     console.log("gettting Active Key", JSON.parse(sessionStorage.getItem("activeKey")));

     /* ---- Active Key ----- */
     const key = JSON.parse(sessionStorage.getItem("activeKey"));
     if(key===null){
       setActivekey(0);
     }
     else{
       setActivekey(JSON.parse(sessionStorage.getItem("activeKey")));
     }

     /* --- Nav Active Key ---- */
     console.log("getting NavActive Key", sessionStorage.getItem("NavActiveKey"));
     const navKey = sessionStorage.getItem("NavActiveKey");
     if(navKey===null || navKey === undefined){
      setNavActiveKey("");
     }
     else{
      setNavActiveKey(sessionStorage.getItem("NavActiveKey"));
     }
   
   },[])
   
       useEffect(()=>{
         console.log("Setting Active Key")
         sessionStorage.setItem("activeKey", activeKey);
        },[activeKey])

        useEffect(()=>{
          console.log("Setting Nav Active Key==>");
          sessionStorage.setItem("NavActiveKey", navActiveKey);
        }, [navActiveKey])


  const handleLogout = () => {
    setLoader(true);
    sessionStorage.clear("userInfo");
    naviagte("/login");
    setLoader(false);
  };

  return (
    <nav className="navbar px-4">
      <a className="navbar-brand d-block mx-auto" href="/dashboard">
        <img
          src={gaonconnectLogo}
          alt="Bootstrap"
          width="auto"
          height="60"
          style={{ borderRadius: "100%" }}
        />
      </a>

      <div
        className="mobile-nav-toggle"
        aria-controls="selectNav"
        aria-expanded="false"
        onClick={setnav}
      >
        <div className="menu-btn__burger"></div>
      </div>

      <ul className="lg-nav">
        <Button className="btn btn-logout" onClick={handleLogout}>
          {loader ? (
            <Spinner animation="border" variant="light" className="spinner" />
          ) : (
            <>
              <Icon.BoxArrowLeft className="me-2" />
              Logout
            </>
          )}
        </Button>
      </ul>

      <ul id="selectNav" className="mobile-nav" data-visible="false">
        <div className="sidenav-lg">
          <Accordion defaultActiveKey={activeKey} flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={ () => {setActivekey(0); navigate("/dashboard"); }} className={activeKey===0 ? "activeMenu": ""}>
            <Icon.ColumnsGap className="me-2" />Dashboard
            </Accordion.Header>
          </Accordion.Item>

                <Accordion.Item eventKey="1">
            <Accordion.Header onClick={ () => {setActivekey(1); navigate("/dashboard/userDetails");}} className={activeKey===1 ? "activeMenu": ""}> 
            <Icon.People className="me-2" />View Users
            </Accordion.Header>
          </Accordion.Item>

              <Accordion.Item eventKey="2" onClick={ () => {setActivekey(2);}} className={activeKey===2 ? "activeMenu": ""}>
            <Accordion.Header>
              <Icon.PencilSquare className="me-2" /> DTG
            </Accordion.Header>
            <Accordion.Body>
              {/* Addinng New Nav Tabs here for handling active state */ }
              <Button onClick={()=>{setNavActiveKey("a"); navigate("/dashboard/CreateUser")}}
                  className={navActiveKey==="a" ? "navActive" :"navNonActive"}><Icon.CardHeading className="me-2" />Add Details</Button>
              
              <Button onClick={() =>{setNavActiveKey("b"); navigate("/dashboard/DistrictDetails")}} 
                className={navActiveKey==="b" ? "navActive" :"navNonActive"}><Icon.CardHeading className="me-2" />District</Button>

              <Button onClick={() => {setNavActiveKey("c"); navigate("/dashboard/TalukaDeatils");}} 
                className={navActiveKey==="c" ? "navActive" :"navNonActive"}><Icon.CardHeading className="me-2" />Taluka</Button>

              <Button onClick={() => {setNavActiveKey("d"); navigate("/dashboard/GrampanchayatDetails");}} 
                className={navActiveKey==="d" ? "navActive" : "navNonActive"}>
                 <Icon.CardHeading className="me-2" />Grampanchayat </Button>


              {/* <Nav variant="pills" defaultActiveKey={navActiveKey} className="d-flex flex-column">
                <Nav.Item>
                  <Nav.Link onClick={()=>{setNavActiveKey("a"); navigate("/dashboard/CreateUser")}} eventKey={navActiveKey} 
                  className={navActiveKey==="a" ? "navActive" :"navNonActive"}><Icon.CardHeading className="me-2" />Add Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link onClick={() =>{setNavActiveKey("b"); navigate("/dashboard/DistrictDetails")}} eventKey={navActiveKey}
                className={navActiveKey==="b" ? "navActive" :"navNonActive"}><Icon.CardHeading className="me-2" />District</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link onClick={() => {setNavActiveKey("c"); navigate("/dashboard/TalukaDeatils");}} eventKey={navActiveKey}
                className={navActiveKey==="c" ? "navActive" :"navNonActive"}><Icon.CardHeading className="me-2" />Taluka
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link onClick={() => {setNavActiveKey("d"); navigate("/dashboard/GrampanchayatDetails");}} eventKey={navActiveKey}
                className={navActiveKey==="d" ? "navActive" : "navNonActive"}>
                 <Icon.CardHeading className="me-2" />Grampanchayat</Nav.Link>
                </Nav.Item>
            </Nav> */}
{/* 
              <Button
                className="w-100 mb-2 btn-accordionMenu"
                onClick={() => {
                  navigate("/dashboard/CreateUser");
                }}
              >
                <Icon.CardHeading className="me-2" />
                Add Details
              </Button>
              <Button
                className="w-100 mb-2 btn-accordionMenu"
                onClick={() => {
                  navigate("/dashboard/DistrictDetails");
                }}
              >District
              </Button>
              <Button
                className="w-100 mb-2 btn-accordionMenu"
                onClick={() => {
                  navigate("/dashboard/TalukaDeatils");
                }}
              >Taluka
              </Button>
              <Button
                className="w-100 mb-2 btn-accordionMenu"
                onClick={() => {
                  navigate("/dashboard/GrampanchayatDetails");
                }}>
                <Icon.CardHeading className="me-2" />
                Grampanchayat
              </Button> */}
            </Accordion.Body>
          </Accordion.Item>
          </Accordion>
        </div>

        <Button className="btn btn-logout m-5" onClick={handleLogout}>
          {loader ? (
            <Spinner animation="border" variant="light" className="spinner" />
          ) : (
            <>
              <Icon.BoxArrowLeft className="me-2" />
              Logout
            </>
          )}
        </Button>
      </ul>
    </nav>
  );
};

export default Menubar;
